export default {
  container: {
    position: `relative`,
    display: `flex`,
    flexDirection: `column`,
    width: `100%`,
    alignItems: `center`,
    marginTop: `2.7vw`,
    paddingLeft: `max(5.3vw, 30px)`,
    paddingRight: `max(5.3vw, 30px)`,
    overflow: `hidden`
  },
  cardsContainer: {
    display: `flex`,
    justifyContent: `center`,
    marginTop: `max(3vw, 45px)`,
    marginBottom: `max(7vw, 45px)`
  },
  textContainer: {
    display: `flex`,
    flexDirection: `column`
  },
  title: {
    fontFamily: `red-hat-regular`,
    fontWeight: 400,
    fontSize: `max(2vw, 40px)`,
    lineHeight: `max(2.76vw, 53px)`,
    color: `#242421`,
    textAlign: `center`
  },
  titleBold: {
    fontFamily: `red-hat-black`,
    fontWeight: 900,
    fontSize: `max(2vw, 40px)`,
    lineHeight: `max(2.76vw, 53px)`,
    color: `#242421`,
    textAlign: `center`
  },
  thankYou: {
    fontFamily: `red-hat-black`,
    fontSize: `max(1.5vw, 30px)`,
    lineHeight: `max(3.6vw, 40px)`,
    color: `#242421`
  },
  rightEllipsis: {
    height: `9rem`,
    position: `absolute`,
    opacity: 0.3,
    zIndex: -1,
    filter: `blur(30px)`,
    '-webkit-backdrop-filter': `blur(30px);`
  },
  leftEllipsis: {
    height: `21rem`,
    position: `absolute`,
    opacity: 0.2,
    zIndex: -1,
    filter: `blur(50px)`,
    '-webkit-backdrop-filter': `blur(50px);`
  }
};
