// Libs imports
import React from 'react';
import styled from 'styled-components';
import YellowCircle from '../../../assets/yellowCircle.png';

import Styles from './functions.styles';

// Landscape Ellipses
const RightTop = styled.img`${Styles.rightTop}`;
const CenterBottom = styled.img`${Styles.centerBottom}`;
const LeftSmall = styled.img`${Styles.leftSmall}`;

// Portrait Ellipses
const RightCircle = styled.img`${Styles.rightCircle}`;
const LeftCircle = styled.img`${Styles.leftCircle}`;

export function LandscapeEllipses() {
  return (
    <>
      <RightTop className="d-none d-lg-flex" src={YellowCircle} />
      <CenterBottom className="d-none d-lg-flex" src={YellowCircle} />
      <LeftSmall className="d-none d-lg-flex" src={YellowCircle} />
    </>
  );
}

export function PortraitEllipses() {
  return (
    <>
      <RightCircle className="d-flex d-lg-none" src={YellowCircle} />
      <LeftCircle className="d-flex d-lg-none" src={YellowCircle} />
    </>
  );
}
