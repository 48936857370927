import React, { useContext } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

// context
import { store } from '../../../../../../store';

// styles
import Styles from './WelcomeBanner.styles';

// assets
import Welcome from './assets/welcome.svg';

// components
import CustomText from '../../../../../../components/atoms/CustomText';

const Container = styled(Row)`${Styles.container}`;
const WelcomeImage = styled.img`${Styles.welcomeImage}`;

function WelcomeBanner() {
  const { t } = useTranslation();

  const { isMobile } = useContext(store);

  return (
    <Container className="m-0 pt-5 pt-xl-0 pb-5 pb-xl-0">
      <Col style={{ paddingLeft: isMobile ? 0 : `18vw` }} xs={12} lg={9}>
        <CustomText
          text={t(`pages.home.welcomeSection.welcome`)}
          type="light-subtitle"
        />
        <CustomText
          text={t(`pages.home.welcomeSection.letsMeet`)}
          type="title"
        />
      </Col>
      <Col
        className="d-flex align-items-center justify-content-center"
        style={{ paddingRight: isMobile ? 0 : `15vw` }}
        xs={12}
        lg={3}
      >
        <WelcomeImage src={Welcome} />
      </Col>
    </Container>
  );
}

export default WelcomeBanner;
