import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

// styles
import Styles from './Hero.styles';

// assets
import YellowCircle from '../../../../assets/yellowCircle.png';

// components
import CustomText from '../../../../components/atoms/CustomText';

const Container = styled.div`${Styles.heroContainer}`;
const TopEllipsis = styled.img`${Styles.topEllipsis}`;
const LeftEllipsis = styled.img`${Styles.leftEllipsis}`;

function Hero() {
  const { t } = useTranslation();

  return (
    <Container>
      <TopEllipsis src={YellowCircle} />
      <CustomText text={t(`pages.aboutUs.hero.usAndOthers`)} type="card-small-description" />
      <CustomText text={t(`pages.aboutUs.hero.title`)} type="big-title" />
      <CustomText text={t(`pages.aboutUs.hero.smallTitle`)} type="card-small-description" />
      <LeftEllipsis src={YellowCircle} className="d-none d-lg-flex" />
    </Container>
  );
}

export default Hero;
