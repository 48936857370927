// Libs imports
import React, { useContext } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { store } from '../../../../store';

// Styles
import Styles from './Telecommunication.styles';

// assets
import {
  BENEFITS_OF_CALL_CENTER,
  BENEFITS_OF_PHONE_APPS,
  BENEFITS_OF_REVIEWS,
  BENEFITS_OF_SURVEY,
  BENEFITS_OF_SWITCH_MAINTENANCE,
  TELECOMMUNICATION_CERTIFICATIONS
} from '../../utils/constants';
import {
  CALL_CENTER,
  DEVELOPMENT_SOFTWARE,
  SECURITY_REVIEWS,
  SPECIALIZED_DEVELOPMENT_CALL_CENTERS,
  SURVEY_SYSTEMS,
  TELECOMMUNICATIONS_ICON,
  TELEPHONY_SERVICES_INTEGRATION
} from '../../assets';

// components
import CategorySection from '../../components/CategorySection/CategorySection';
import CustomText from '../../../../components/atoms/CustomText';
import BenefitsContainer from '../../components/BenefitsContainer/BenefitsContainer';
import Contact from '../../../../components/molecules/Contact';
import AWSCertifications from '../../components/AWSCertifications/AWSCertifications';
import CategoryWithEllipsesIcon from '../../components/CategoryWithEllipsesIcon/CategoryWithEllipsesIcon';

const ServiceContainer = styled.div`${Styles.container}`;
const CategoryIcon = styled.img`${Styles.categoryIcon}`;
const ServiceConstellation = styled.img`${Styles.serviceConstellation}`;
const Element = styled.div``;

function Telecommunication() {
  const { t } = useTranslation();
  const { isMobile } = useContext(store);

  return (
    <ServiceContainer>
      <ServiceConstellation
        style={{
          marginLeft: isMobile ? `30px` : `12.9vw`,
          marginTop: isMobile ? `50px` : `8vw`
        }}
        src={TELECOMMUNICATIONS_ICON}
      />
      <CategorySection
        withEllipses
        leftElement={isMobile ? (
          <Element className="mb-5">
            <CustomText
              text={t(`pages.telecommunication.titlePage`)}
              type="title"
              className="mb-5"
              size="8vw"
            />
            <CategoryWithEllipsesIcon src={DEVELOPMENT_SOFTWARE} width={isMobile ? `300px` : `30vw`} />
          </Element>
        ) : (
          <Element>
            <CustomText
              text={t(`pages.telecommunication.titlePage`)}
              type="title"
              className="mb-5"
            />
            <CustomText
              text={t(`pages.telecommunication.titleDescription`)}
              type="caption"
            />
          </Element>
        )}
        rightElement={isMobile ? (
          <CustomText
            text={t(`pages.telecommunication.titleDescription`)}
            type="caption"
          />
        ) : (<CategoryWithEllipsesIcon src={DEVELOPMENT_SOFTWARE} width={isMobile ? `30vw` : `30vw`} />)}
      />
      <CategorySection
        leftElement={(
          <Element>
            <CustomText
              text={t(`pages.telecommunication.switchMaintenanceTitle`)}
              type="subtitle-with-border"
              className="mb-5"
            />
            <CustomText
              text={t(`pages.telecommunication.switchMaintenanceDescription`)}
              type="caption"
              className={isMobile ? `mb-3` : ``}
            />
          </Element>
        )}
        rightElement={(
          <CategoryIcon src={CALL_CENTER} width={isMobile ? `300px` : `600px`} />)}
      />
      <BenefitsContainer benefits={BENEFITS_OF_SWITCH_MAINTENANCE} />
      <CategorySection
        leftElement={(<CategoryIcon src={SPECIALIZED_DEVELOPMENT_CALL_CENTERS} width={isMobile ? `300px` : `600px`} />)}
        rightElement={(
          <Element className={isMobile ? `mt-3` : ``}>
            <CustomText
              text={t(`pages.telecommunication.callCenterTitle`)}
              type="subtitle-with-border"
              className="mb-5"
            />
            <CustomText
              text={t(`pages.telecommunication.callCenterDescription`)}
              type="caption"
            />
          </Element>
        )}
      />
      <BenefitsContainer benefits={BENEFITS_OF_CALL_CENTER} />
      <CategorySection
        leftElement={(
          <Element>
            <CustomText
              text={t(`pages.telecommunication.phoneAppsTitle`)}
              type="subtitle-with-border"
              className="mb-5"
            />
            <CustomText
              text={t(`pages.telecommunication.phoneAppsDescription`)}
              type="caption"
              className={isMobile ? `mb-3` : ``}
            />
          </Element>
        )}
        rightElement={(<CategoryIcon src={TELEPHONY_SERVICES_INTEGRATION} width={isMobile ? `300px` : `600px`} />)}
      />
      <BenefitsContainer benefits={BENEFITS_OF_PHONE_APPS} />
      <CategorySection
        leftElement={(<CategoryIcon src={SURVEY_SYSTEMS} width={isMobile ? `300px` : `600px`} />)}
        rightElement={(
          <Element className={isMobile ? `mt-3` : ``}>
            <CustomText
              text={t(`pages.telecommunication.surveyTitle`)}
              type="subtitle-with-border"
              className="mb-5"
            />
            <CustomText
              text={t(`pages.telecommunication.surveyDescription`)}
              type="caption"
            />
          </Element>
        )}
      />
      <BenefitsContainer benefits={BENEFITS_OF_SURVEY} />
      <CategorySection
        leftElement={(
          <Element>
            <CustomText
              text={t(`pages.telecommunication.reviewsTitle`)}
              type="subtitle-with-border"
              className="mb-5"
            />
            <CustomText
              text={t(`pages.telecommunication.reviewsDescription`)}
              type="caption"
              className={isMobile ? `mb-3` : ``}
            />
          </Element>
        )}
        rightElement={(<CategoryIcon src={SECURITY_REVIEWS} width={isMobile ? `300px` : `600px`} />)}
      />
      <BenefitsContainer benefits={BENEFITS_OF_REVIEWS} />
      <AWSCertifications certifications={TELECOMMUNICATION_CERTIFICATIONS} />
      <Contact />
    </ServiceContainer>
  );
}

export default Telecommunication;
