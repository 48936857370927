import React from 'react';

// components
import ContactBanner from './components/ContactBanner';
import ContactButtons from './components/ContactButtons';

function Contact() {
  return (
    <>
      <ContactBanner />
      <ContactButtons />
    </>
  );
}

export default Contact;
