export default {
  container: {
    position: `relative`,
    display: `flex`,
    flexDirection: `column`,
    width: `100%`,
    alignItems: `center`,
    marginTop: `2.7vw`,
    paddingLeft: `5.3vw`,
    paddingRight: `5.3vw`,
    marginBottom: `2.7vw`
  },
  cardsContainer: {
    display: `flex`,
    justifyContent: `center`
  }
};
