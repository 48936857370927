import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

// assets
import Styles from './Footer.styles';
import LogoCignuzWhite from '../../assets/logoCignuzWhite.svg';
import Location from './assets/location.svg';
import Email from './assets/email.svg';
import Phone from './assets/phone.svg';
import Whatsapp from './assets/whatsapp.svg';

// components
import CustomText from '../atoms/CustomText';
import Option from './components/Option';

const FooterContainer = styled(Container)`${Styles.footerContainer}`;
const Logo = styled.img`${Styles.logo}`;

function Footer() {
  const { t, i18n } = useTranslation();
  const lang = i18n.resolvedLanguage;

  const createServiceLink = (servicePath) => `/${lang}/${t(`routes.services`)}/${servicePath}`;

  return (
    <FooterContainer>
      <Row className="m-0">
        <Col sm={12} md={6} lg={4} className="text-center">
          <Logo src={LogoCignuzWhite} />
        </Col>
        <Col sm={12} md={6} lg={3}>
          <CustomText text={t(`footer.sectionTitles.services`)} type="footer-section-title" />
          <Option link={createServiceLink(t(`routes.subServices.softwareDev`))}>
            {t(`footer.services.softwareDev`)}
          </Option>
          <Option link={createServiceLink(t(`routes.subServices.infrastructure`))}>
            {t(`footer.services.infrastructure`)}
          </Option>
          <Option link={createServiceLink(t(`routes.subServices.telecommunication`))}>
            {t(`footer.services.telecommunication`)}
          </Option>
          <Option link={createServiceLink(t(`routes.subServices.security`))}>
            {t(`footer.services.security`)}
          </Option>
          <Option link={createServiceLink(t(`routes.subServices.design`))}>
            {t(`footer.services.design`)}
          </Option>
          <Option link={createServiceLink(t(`routes.subServices.consultancy`))}>
            {t(`footer.services.consultancy`)}
          </Option>
        </Col>
        <Col sm={12} md={6} lg={2}>
          <CustomText text={t(`footer.sectionTitles.company`)} type="footer-section-title" />
          <Option link={`/${lang}/${t(`routes.aboutUs`)}`}>
            {t(`footer.company.aboutUs`)}
          </Option>
          <Option link={`/${lang}/${t(`routes.policies`)}`}>
            {t(`footer.company.policies`)}
          </Option>
          <Option link={`/${lang}/${t(`routes.contact`)}`}>
            {t(`footer.company.contact`)}
          </Option>
          <CustomText text={t(`footer.sectionTitles.careers`)} type="footer-section-title" />
          <Option preventDefault link={`mailto:${t(`footer.careersMail`)}`} marginTop={20}>
            {t(`footer.careersMail`)}
          </Option>
        </Col>
        <Col sm={12} md={6} lg={3}>
          <CustomText text={t(`footer.sectionTitles.contact`)} type="footer-section-title" />
          <Option
            maxWidth="16.2rem"
            justify
            preventDefault
            icon={Location}
            link="https://goo.gl/maps/Pbzbu85EtKLJxHpVA"
          >
            {t(`footer.contact.address`)}
          </Option>
          <Option
            preventDefault
            icon={Email}
            link={`mailto:${t(`footer.contact.contactMail`)}`}
          >
            {t(`footer.contact.contactMail`)}
          </Option>
          <Option
            preventDefault
            icon={Phone}
            link={`tel:${t(`footer.contact.contactPhone`)}`}
          >
            {t(`footer.contact.contactPhone`)}
          </Option>
          <Option
            preventDefault
            icon={Whatsapp}
            link={`https://wa.me/${t(`footer.contact.whatsappPhone`)}`}
          >
            {t(`footer.contact.whatsappPhoneLabel`)}
          </Option>
        </Col>
      </Row>
    </FooterContainer>
  );
}

export default Footer;
