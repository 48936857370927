export default {
  container: {
    display: `flex`,
    paddingTop: `max(1.67vw, 40px)`,
    paddingLeft: `max(3vw, 40px)`,
    paddingRight: `max(3vw, 40px)`,
    paddingBottom: `max(2.4vw, 40px)`,
    margin: `max(0.2vw, 8px)`,
    flexDirection: `column`,
    borderRadius: `30px`,
    backgroundColor: `#FFFFFF`,
    boxShadow: `0px 20px 99px #00000015`
  },
  img: {
    display: `flex`,
    height: `max(5.5vw, 100px)`,
    objectFit: `contain`,
    alignSelf: `flex-start`
  }
};
