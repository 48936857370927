export default {
  container: {
    position: `relative`,
    display: `flex`,
    flexDirection: `column`,
    alignItems: `center`,
    marginTop: `80px`,
    width: `100%`,
    maxWidth: `100%`
  }
};
