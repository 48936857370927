const styles = {
  CheckboxContainer: {
    display: `inline-flex`,
    alignItems: `center`
  },
  StyledCheckbox: {
    '@media (max-width: 992px)': {
      width: `25px`,
      height: `25px`
    },
    width: `25px`,
    height: `25px`,
    borderRadius: `50%`,
    cursor: `pointer`,
    // outline: `5px auto transparent !important`,
    border: (props) => (props.checked ? `0px solid transparent` : `1px solid #242421`),
    backgroundColor: (props) => (props.checked ? `#FFD524` : `transparent`),
    boxShadow: (props) => (props.checked ? `0px 3px 99px #58585829` : `0px`),
    transition: `box-shadow 0.3s ease`,
    '&:hover': {
      boxShadow: `5px 8px 10px #58585829`
    },
    '&:checked': {
      border: `0px solid transparent !important`
    }
  },
  CheckboxButton: {
    cursor: `pointer`,
    flexWrap: `nowrap`,
    padding: `5px 10px`,
    borderRadius: `5px`,
    color: `#242421`,
    width: `80%`,
    fontFamily: `geologica-regular`,
    border: `0px !important`,
    '&:checked': {
      border: `0px solid transparent !important`
    }
  }

};

export default styles;
