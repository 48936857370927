export default {
  cardContainer: {
    paddingTop: `80px`,
    paddingBottom: `80px`,
    backgroundColor: `#F7F7F7`,
    position: `relative`,
    display: `flex`,
    flexDirection: `column`,
    width: `100%`,
    alignItems: `center`,
    marginTop: `2.7vw`,
    marginLeft: 0,
    marginRight: 0,
    paddingLeft: `min(20vw, 30px)`,
    paddingRight: `min(16vw, 30px)`,
    justifyContent: `space-around`
  },
  card: {
    display: `flex`,
    justifyContent: `center`,
    borderRadius: 30,
    paddingTop: `max(1.67vw, 13px)`,
    paddingLeft: `max(1vw, 30px)`,
    paddingRight: `max(1vw, 30px)`,
    margin: `max(0.5vw, 8px)`,
    flexDirection: `column`,
    backgroundColor: `#FFFFFF`,
    boxShadow: `0px 20px 99px #00000015`,
    maxWidth: `265px`
  },
  cardMobile: {
    display: `flex`,
    justifyContent: `center`,
    borderRadius: 30,
    paddingTop: `max(1.67vw, 13px)`,
    paddingLeft: `max(1vw, 30px)`,
    paddingRight: `max(1vw, 30px)`,
    margin: `max(0.5vw, 8px)`,
    flexDirection: `column`,
    backgroundColor: `#FFFFFF`,
    boxShadow: `0px 20px 99px #00000015`,
    maxWidth: `350px`
  }
};
