import React, { useContext } from 'react';
import styled from 'styled-components';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { useTranslation } from 'react-i18next';

// context
import { store } from '../../../../store';

// assets
import Constellation from '../../../../assets/constellation_gray.svg';

// styles
import Styles from './NameOrigin.styles';

// components
import CustomText from '../../../../components/atoms/CustomText';

const Container = styled(Row)`${Styles.container}`;
const ConstellationImg = styled.img`${Styles.constellation}`;

function NameOrigin() {
  const { t } = useTranslation();

  const { isMobile } = useContext(store);

  return (
    <Container
      className="m-0 pl-md-5 pt-5 pb-5"
      style={{
        paddingLeft: isMobile ? `30px` : `19vw`,
        paddingRight: isMobile ? `30px` : `15vw`
      }}
    >
      <Col className="d-flex align-items-center" xs="auto">
        <ConstellationImg src={Constellation} />
      </Col>
      <Col className="pl-md-5" xs={12} md={7}>
        <CustomText text={t(`pages.aboutUs.nameOrigin.title`)} type="subtitle" className="mb-3" />
        <CustomText text={t(`pages.aboutUs.nameOrigin.paragraph1`)} type="caption" className="mb-3" />
        <CustomText text={t(`pages.aboutUs.nameOrigin.paragraph2`)} type="caption" className="mb-3" />
        <CustomText text={t(`pages.aboutUs.nameOrigin.paragraph3`)} type="caption" className="mb-3" />
      </Col>
    </Container>
  );
}

export default NameOrigin;
