import React, { useContext } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

// context
import { store } from '../../../../store';

// styles
import Styles from './Anticorruption.styles';

// assets
import mechanics from '../../../../assets/files/mechanisms.pdf';

// components
import CustomText from '../../../../components/atoms/CustomText';
import BaseButton from '../../../../components/atoms/BaseButton';

const Container = styled(Row)`${Styles.container}`;

function AntiCorruption() {
  const { t } = useTranslation();
  const { isMobile } = useContext(store);

  return (
    <Container
      style={{
        paddingLeft: isMobile ? `30px` : `19vw`,
        paddingRight: isMobile ? `30px` : `15vw`,
        paddingTop: isMobile ? `43px` : `4.63vw`,
        paddingBottom: isMobile ? `28px` : `4.94vw`
      }}
      className="m-0"
    >
      <Col xs={12} lg={5}>
        <CustomText text={t(`pages.policies.anticorruption.title`)} type="subtitle" lineHeight="53px" />
      </Col>
      <Col xs={12} lg={7}>
        <CustomText text={t(`pages.policies.anticorruption.description`)} type="caption" />
        <BaseButton
          type="file"
          label={t(`pages.policies.anticorruption.buttonText`)}
          onClick={(e) => {
            e.preventDefault();
            window.open(`${mechanics}`);
          }}
          maxWidth="100%"
          iconStyles={{
            width: `55px`,
            marginLeft: `10px`,
            marginRight: `5px`
          }}
        />
      </Col>
    </Container>
  );
}

export default AntiCorruption;
