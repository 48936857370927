// Libs imports
import React from 'react';
import styled from 'styled-components';

// Styles
import Styles from './Home.styles';

// Components
import Hero from './components/Hero';
import Welcome from './components/Welcome';
import WhatWeDo from './components/WhatWeDo';
import Achievements from './components/Achievements';
import Contact from '../../components/molecules/Contact';

const HomeContainer = styled.div`${Styles.container}`;

function Home() {
  return (
    <HomeContainer>
      <Hero />
      <Welcome />
      <WhatWeDo />
      <Achievements />
      <Contact />
    </HomeContainer>
  );
}

export default Home;
