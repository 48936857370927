// Libs imports
import React, { useContext } from 'react';
import styled from 'styled-components';
import { useTranslation } from "react-i18next";
import { Row, Toast } from 'react-bootstrap';

// context
import { store } from '../../../../store';

// Styles
import Styles from './BenefitsContainer.styles';

// components
import CustomText from '../../../../components/atoms/CustomText';

function BenefitsContainer({ benefits }) {
  const { t } = useTranslation();
  const { isMobile } = useContext(store);

  const Card = isMobile ? styled(Toast)`${Styles.cardMobile}` : styled(Toast)`${Styles.card}`;
  const CardContainer = styled(Row)`${Styles.cardContainer}`;
  const CardIcon = styled.img`${Styles.cardIcon}`;

  return (
    <CardContainer>
      <CustomText
        text={t(`pages.services.benefitsTitle`)}
        type="subtitle"
        marginBottom={22}
      />
      <Row className="m-0" style={{ justifyContent: `center` }}>
        {benefits.map((item, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <Card key={`${index}`}>
            <CardIcon src={item.icon} width={110} />
            <CustomText
              text={`0${index + 1}`}
              type="caption-bold-grey"
              marginBottom={0}
            />
            <CustomText
              text={t(item.text)}
              marginBottom="10%"
              type="card-small-description"
              size={16}
            />
          </Card>
        ))}
      </Row>

    </CardContainer>
  );
}

export default BenefitsContainer;
