import React, { useContext } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

// context
import { store } from '../../../../../store';

// styles
import Styles from './ContactBanner.styles';

// assets
import Phone from './assets/contact.svg';

// components
import CustomText from '../../../../atoms/CustomText';

const Container = styled(Row)`${Styles.container}`;
const Image = styled.img`${Styles.image}`;

function ContactBanner() {
  const { t } = useTranslation();

  const { isMobile } = useContext(store);

  return (
    <Container>
      <Col
        className="d-flex align-items-center justify-content-center"
        style={{
          paddingLeft: isMobile ? 0 : `17vw`,
          paddingTop: isMobile ? `70px` : `40px`,
          paddingBottom: isMobile ? `50px` : `40px`
        }}
        xs={12}
        lg={3}
      >
        <Image src={Phone} />
      </Col>
      <Col
        style={{
          paddingLeft: isMobile ? `30px` : `8vw`,
          paddingBottom: isMobile ? `70px` : 0
        }}
        xs={12}
        lg={9}
      >
        <CustomText
          text={t(`components.contactBanner.niceToMeetYou`)}
          type="caption-more-letter-spacing"
        />
        <CustomText
          text={t(`components.contactBanner.keepInTouch`)}
          type="subtitle"
        />
        <CustomText
          text={t(`components.contactBanner.comment`)}
          type="caption"
        />
      </Col>
    </Container>
  );
}

export default ContactBanner;
