// Libs imports
import React, { useContext } from 'react';
import styled from 'styled-components';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

// context
import { store } from '../../../../store';

// Styles
import Styles from './CategorySection.styles';

// components
import { LandscapeEllipses, PortraitEllipses } from '../../utils/functions';

const CategoryContainer = styled.div`${Styles.categoryContainer}`;

function CategorySection({
  leftElement,
  rightElement,
  withEllipses
}) {
  const { isMobile } = useContext(store);

  return (
    <CategoryContainer
      style={{
        paddingLeft: isMobile ? `30px` : `12.9vw`,
        paddingRight: isMobile ? `30px` : `10.5vw`
      }}
    >
      <Row>
        <Col className="d-flex">
          {leftElement}
        </Col>
        <Col className="d-flex justify-content-center">
          {rightElement}
        </Col>
      </Row>
      {withEllipses && (
        <>
          <LandscapeEllipses />
          <PortraitEllipses />
        </>
      )}
    </CategoryContainer>
  );
}

export default CategorySection;
