export default {
  footerContainer: {
    position: `relative`,
    paddingTop: `5rem`,
    paddingBottom: `2rem`,
    backgroundColor: `#242421`,
    width: `100%`,
    maxWidth: `100%`,
    zIndex: 25
  },
  logo: {
    marginTop: `10px`,
    marginBottom: `20px`,
    height: `2.75rem`,
    justifySelf: `center`
  }
};
