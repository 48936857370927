import React, { useContext } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import CustomText from '../../../../../../components/atoms/CustomText';

// context
import { store } from '../../../../../../store';

// styles
import Styles from './Description.styles';

// components

const DescriptionContainer = styled(Col)`${Styles.container}`;
const TitleContainer = styled(Row)`${Styles.titleContainer}`;

function Description() {
  const { t } = useTranslation();

  const { isMobile } = useContext(store);

  return (
    <DescriptionContainer
      style={{
        paddingLeft: isMobile ? `30px` : `19vw`,
        paddingRight: isMobile ? `30px` : `15vw`
      }}
    >
      <CustomText text={t(`pages.home.welcomeSection.us`)} type="caption" marginBottom="1rem" />
      <CustomText text={t(`pages.home.welcomeSection.ourGoal`)} type="caption" />
      <TitleContainer>
        <CustomText text={t(`pages.home.welcomeSection.title`)} type="subtitle" />
      </TitleContainer>
      <CustomText text={t(`pages.home.welcomeSection.ourTeam`)} type="caption" />
      <CustomText text={t(`pages.home.welcomeSection.thanks`)} type="caption" />
    </DescriptionContainer>
  );
}

export default Description;
