import React from 'react';

// components
import WelcomeBanner from './components/WelcomeBanner';
import Description from './components/Description';

function Welcome() {
  return (
    <>
      <WelcomeBanner />
      <Description />
    </>
  );
}

export default Welcome;
