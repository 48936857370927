// Libs imports
import React, { useContext } from 'react';
import styled from 'styled-components';

// Styles
import { useTranslation } from "react-i18next";
import Container from 'react-bootstrap/Container';
import { Image, Row } from 'react-bootstrap';
import Styles from './AWSCertifications.styles';
import CustomText from '../../../../components/atoms/CustomText';

// context
import { store } from '../../../../store';

const CertificationsContainer = styled(Container)`${Styles.certificationsContainer}`;

function AWSCertifications({ certifications }) {
  const { t } = useTranslation();
  const { isMobile } = useContext(store);

  return (
    <CertificationsContainer>
      <CustomText
        text={t(`pages.services.ourCertifications`)}
        type="subtitle"
        marginBottom={70}
        marginTop={40}
      />
      <Row className="m-0" style={{ justifyContent: isMobile ? `center` : `space-between` }}>
        {certifications.map((item, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <Image key={index} src={item.src} width={200} height={200} />
        ))}
      </Row>

    </CertificationsContainer>
  );
}

export default AWSCertifications;
