// Libs imports
import React, { useContext } from 'react';
import styled from 'styled-components';
import { useTranslation } from "react-i18next";

// context
import { store } from '../../store';

// Styles
import Styles from './Contact.styles';

// Assets
import YellowCircle from '../../assets/yellowCircle.png';

// Components
import ContactCard from './components/ContactCard';
import CustomText from '../../components/atoms/CustomText';

const Container = styled.div`${Styles.container}`;
const TopEllipsis = styled.img`${Styles.topEllipsis}`;
const LeftEllipsis = styled.img`${Styles.leftEllipsis}`;

function Contact() {
  const { t } = useTranslation();
  const { isMobile } = useContext(store);

  return (
    <Container
      style={{
        paddingLeft: isMobile ? `20px` : `12vw`,
        paddingRight: isMobile ? `20px` : `11vw`
      }}
    >
      <TopEllipsis src={YellowCircle} />
      <CustomText text={t(`pages.contact.title`)} type="big-title" />
      <CustomText text={t(`pages.contact.smallTitle`)} type="card-small-description" textAlign="center" letterSpacing="2px" />
      <LeftEllipsis src={YellowCircle} className="d-none d-lg-flex" />
      <ContactCard />
    </Container>
  );
}

export default Contact;
