import React, { useEffect, useState, useContext } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

// context
import { store } from '../../../store';

// styles
import Styles from './styles';

// components
import presets from './presets';
import CustomText from '../CustomText';

const StyledButton = styled.a`${Styles.button}`;
const Icon = styled.img`${Styles.icon}`;

function ContactButtons({
  type, onClick, children = null, label = null, className = ``, styles = {}, iconStyles = {}, maxWidth = null
}) {
  const { t } = useTranslation();
  const { isMobile } = useContext(store);
  const [hover, setHover] = useState(false);
  const [icon, setIcon] = useState(null);

  const preset = presets[type] ?? {};
  const innerOnClick = onClick ?? preset.onClick ?? (() => {});
  const innerLabel = label ?? t(preset.labelKey ?? ``);

  useEffect(() => {
    if ((isMobile || hover) && preset.activeIcon) {
      setIcon(<Icon style={iconStyles} src={preset.activeIcon} />);
    } else if (!hover && preset.inactiveIcon) {
      setIcon(<Icon style={iconStyles} src={preset.inactiveIcon} />);
    } else {
      setIcon(null);
    }
  }, [hover, isMobile]);

  return (
    <StyledButton
      className={className}
      style={styles}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onClick={innerOnClick}
      maxWidth={maxWidth}
    >
      {icon}
      {children ?? <CustomText text={innerLabel} type="caption-regular" size="25px" />}
    </StyledButton>
  );
}

export default ContactButtons;
