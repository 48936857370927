import React, { useContext } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Row from 'react-bootstrap/Row';

// context
import { store } from '../../../../store';

// styles
import Styles from './WhatWeDo.styles';

// assets
import softwareDevActive from '../../../../assets/constellation/softwareDevelopmentActive.svg';
import softwareDevInactive from '../../../../assets/constellation/softwareDevelopmentInactive.svg';
import infrastructureActive from '../../../../assets/constellation/infrastructureActive.svg';
import infrastructureInactive from '../../../../assets/constellation/infrastructureInactive.svg';
import telecommunicationsActive from '../../../../assets/constellation/telecommunicationsActive.svg';
import telecommunicationsInactive from '../../../../assets/constellation/telecommunicationsInactive.svg';
import securityActive from '../../../../assets/constellation/securityActive.svg';
import securityInactive from '../../../../assets/constellation/securityInactive.svg';
import designActive from '../../../../assets/constellation/designActive.svg';
import designInactive from '../../../../assets/constellation/designInactive.svg';
import consultancyActive from '../../../../assets/constellation/consultancyActive.svg';
import consultancyInactive from '../../../../assets/constellation/consultancyInactive.svg';
import YellowCircle from '../../../../assets/yellowCircle.png';

// Components
import CustomText from '../../../../components/atoms/CustomText';
import CardWithDescription from '../../../../components/atoms/CardWithDescription';

const MainContainer = styled.div`${Styles.container}`;
const CardsContainer = styled(Row)``;
const Ellipsis = styled.img`${Styles.ellipsis}`;

function WhatWeDo() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const { isMobile } = useContext(store);

  const goToPage = (servicePath) => {
    navigate(`/${i18n.resolvedLanguage}/${t(`routes.services`)}/${servicePath}`);
  };

  return (
    <MainContainer
      style={{
        paddingLeft: isMobile ? `30px` : `5.3vw`,
        paddingRight: isMobile ? `30px` : `5.3vw`
      }}
    >
      <CustomText text={t(`pages.home.whatWeDoSection.title`)} type="title" />
      <CardsContainer className="m-0">
        <CardWithDescription
          title={t(`pages.home.whatWeDoSection.cards.softwareDev.title`)}
          onClick={() => { goToPage(`${t(`routes.subServices.softwareDev`)}`); }}
          description={t(`pages.home.whatWeDoSection.cards.softwareDev.description`)}
          image={softwareDevInactive}
          imageHover={softwareDevActive}
        />
        <CardWithDescription
          title={t(`pages.home.whatWeDoSection.cards.infrastructure.title`)}
          onClick={() => { goToPage(`${t(`routes.subServices.infrastructure`)}`); }}
          description={t(`pages.home.whatWeDoSection.cards.infrastructure.description`)}
          image={infrastructureInactive}
          imageHover={infrastructureActive}
        />
        <CardWithDescription
          title={t(`pages.home.whatWeDoSection.cards.telecommunication.title`)}
          onClick={() => { goToPage(`${t(`routes.subServices.telecommunication`)}`); }}
          description={t(`pages.home.whatWeDoSection.cards.telecommunication.description`)}
          image={telecommunicationsInactive}
          imageHover={telecommunicationsActive}
        />
        <CardWithDescription
          title={t(`pages.home.whatWeDoSection.cards.security.title`)}
          onClick={() => { goToPage(`${t(`routes.subServices.security`)}`); }}
          description={t(`pages.home.whatWeDoSection.cards.security.description`)}
          image={securityInactive}
          imageHover={securityActive}
        />
        <CardWithDescription
          title={t(`pages.home.whatWeDoSection.cards.design.title`)}
          onClick={() => { goToPage(`${t(`routes.subServices.design`)}`); }}
          description={t(`pages.home.whatWeDoSection.cards.design.description`)}
          image={designInactive}
          imageHover={designActive}
        />
        <CardWithDescription
          title={t(`pages.home.whatWeDoSection.cards.consultancy.title`)}
          onClick={() => { goToPage(`${t(`routes.subServices.consultancy`)}`); }}
          description={t(`pages.home.whatWeDoSection.cards.consultancy.description`)}
          image={consultancyInactive}
          imageHover={consultancyActive}
        />
      </CardsContainer>
      <Ellipsis className="d-none d-md-flex" src={YellowCircle} />
    </MainContainer>
  );
}

export default WhatWeDo;
