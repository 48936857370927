export default {
  rightTop: {
    position: `absolute`,
    height: `16.5rem`,
    opacity: 0.3,
    filter: `blur(50px)`,
    '-webkit-backdrop-filter': `blur(50px);`,
    zIndex: -1,
    top: `-5%`,
    right: `5%`
  },
  centerBottom: {
    position: `absolute`,
    height: `32.6rem`,
    opacity: 0.2,
    filter: `blur(50px)`,
    '-webkit-backdrop-filter': `blur(50px);`,
    zIndex: -1,
    bottom: `-40%`
  },
  leftSmall: {
    position: `absolute`,
    height: `3.25rem`,
    opacity: 0.66,
    filter: `blur(33px)`,
    '-webkit-backdrop-filter': `blur(50px);`,
    zIndex: -1,
    left: `5%`,
    top: `15%`
  },
  rightCircle: {
    position: `absolute`,
    height: `7rem`,
    opacity: 0.3,
    filter: `blur(50px)`,
    '-webkit-backdrop-filter': `blur(50px);`,
    zIndex: -1,
    top: `8%`,
    right: 0
  },
  leftCircle: {
    position: `absolute`,
    height: `14rem`,
    opacity: 0.15,
    filter: `blur(50px)`,
    '-webkit-backdrop-filter': `blur(50px);`,
    zIndex: -1,
    top: `30%`,
    left: `-15%`
  }
};
