import React from 'react';
import Styles from './CategoryWithEllipsesIcon.styles';

function CategoryWithEllipsesIcon({ src, width, height }) {
  return (
    <div style={Styles.container}>
      <div style={Styles.circles}>
        <div style={Styles.circle1} />
        <img
          src={src}
          alt="Category Icon"
          style={{
            width,
            height,
            zIndex: 2,
            borderRadius: 1000
          }}
        />
        <div style={Styles.circle2} />
      </div>
    </div>
  );
}

export default CategoryWithEllipsesIcon;
