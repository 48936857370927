import React, { useMemo, useContext } from 'react';
// eslint-disable-next-line
import L from 'leaflet';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import {
  MapContainer, TileLayer, Marker
} from 'react-leaflet';
import { Row, Col } from 'react-bootstrap';

// context
import { store } from '../../../../store';

// styles
import Styles from './ContactCard.styles';
// eslint-disable-next-line
import 'leaflet/dist/leaflet.css';

// assets
import MapMarker from '../../../../assets/map_marker.svg';

// components
import BaseButton from '../../../../components/atoms/BaseButton';
import CustomText from '../../../../components/atoms/CustomText';

const MainContainer = styled.div`${Styles.container}`;
const CardsContainer = styled(Row)`${Styles.cardsContainer}`;
const MainCard = styled.div`${Styles.mainCard}`;
const TitleContainer = styled(Row)`${Styles.titleContainer}`;
const LeftCol = styled(Col)`${Styles.col}`;
const RightCol = styled(Col)`${Styles.col}`;
const MapWrapper = styled.a`${Styles.mapContainer}`;
const MapMarkerIcon = new L.Icon({
  iconUrl: MapMarker,
  iconRetinaUrl: MapMarker,
  iconAnchor: [5, 25],
  iconSize: [35, 55]
});

function ContactCard() {
  const { t } = useTranslation();
  const { isMobile } = useContext(store);

  const map = useMemo(() => (
    <MapContainer
      center={[20.65691833466368, -103.39812348714302]}
      zoom={17}
      scrollWheelZoom={false}
      zoomControl={false}
      dragging={false}
      touchZoom={false}
      doubleClickZoom={false}
      boxZoom={false}
      attributionControl={false}
      keyboard={false}
      tap={false}
      style={{ flex: 1, width: `100%` }}
    >
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
        url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png"
      />
      <Marker position={[20.65691833466368, -103.39812348714302]} icon={MapMarkerIcon} />
    </MapContainer>
  ), []);

  return (
    <MainContainer>
      <CardsContainer className="m-0">
        <MainCard>
          <Row style={{ display: `flex`, flexWrap: `wrap` }}>
            <LeftCol lg={6}>
              <TitleContainer>
                <CustomText text={t(`Comunícate ${isMobile ? `con nosotr@s` : ``}`)} type="subtitle" lineHeight="max(3.33vw, 50px)" />
                {!isMobile
                && (
                  <>
                    &nbsp;&nbsp;&nbsp;
                    <CustomText text={t(` con nosotr@s `)} type="light-subtitle" letterSpacing="normal" lineHeight="max(3.33vw, 50px)" />
                  </>
                )}
                <CustomText text={t(`Lunes a viernes de 9:00 a.m. a 6:00 p.m.`)} type="caption" />
                <CustomText text={`Av. Cubilete 2963, Interior 202, Colonia Rinconada del Sol, <span style="white-space: nowrap;">C.P. 45055</span>, Zapopan, Jalisco, Mexico.`} type="caption" />
                <CustomText text={t(``)} type="caption" />
              </TitleContainer>
              <BaseButton type="phone" />
              <BaseButton type="email" />
              <BaseButton type="careers" />
            </LeftCol>
            <RightCol lg={6} style={{ alignItems: `normal` }}>
              <MapWrapper href="https://www.google.com.mx/maps/place/cignuz();/@20.6567648,-103.4007076,17z/data=!3m1!4b1!4m6!3m5!1s0x8428add6c1399d33:0xc386351adb032938!8m2!3d20.6567648!4d-103.3981327!16s%2Fg%2F11fy9j50fy?entry=ttu" target="_blank">
                {map}
              </MapWrapper>
            </RightCol>
          </Row>
        </MainCard>
      </CardsContainer>
    </MainContainer>
  );
}

export default ContactCard;
