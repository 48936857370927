// Libs imports
import React, { useContext } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

// context
import { store } from '../../../../store';

// Styles
import Styles from './Consulting.styles';

// assets
import { BENEFITS_OF_CONSULTING } from '../../utils/constants';
import {
  CONSULTING,
  CONSULTING_ICON,
  CONSULTING_IMG
} from '../../assets';

// copmponents
import CategorySection from '../../components/CategorySection/CategorySection';
import CustomText from '../../../../components/atoms/CustomText';
import BenefitsContainer from '../../components/BenefitsContainer/BenefitsContainer';
import Contact from '../../../../components/molecules/Contact';
import CategoryWithEllipsesIcon from '../../components/CategoryWithEllipsesIcon/CategoryWithEllipsesIcon';

const ServiceContainer = styled.div`${Styles.container}`;
const CategoryIcon = styled.img`${Styles.categoryIcon}`;
const ServiceConstellation = styled.img`${Styles.serviceConstellation}`;
const Element = styled.div``;

function Consulting() {
  const { t } = useTranslation();
  const { isMobile } = useContext(store);

  return (
    <ServiceContainer>
      <ServiceConstellation
        style={{
          marginLeft: isMobile ? `30px` : `12.9vw`,
          marginTop: isMobile ? `50px` : `8vw`
        }}
        src={CONSULTING_ICON}
      />
      <CategorySection
        withEllipses
        leftElement={isMobile ? (
          <Element className="mb-5">
            <CustomText
              text={t(`pages.consulting.title`)}
              type="title"
              className="mb-5"
            />
            <CategoryWithEllipsesIcon src={CONSULTING_IMG} width={isMobile ? `300px` : `30vw`} />
          </Element>
        ) : (
          <Element>
            <CustomText
              text={t(`pages.consulting.title`)}
              type="title"
              className="mb-5"
            />
            <CustomText
              text={t(`pages.consulting.description`)}
              type="caption"
            />
          </Element>
        )}
        rightElement={isMobile ? (
          <CustomText
            text={t(`pages.consulting.description`)}
            type="caption"
          />
        ) : (<CategoryWithEllipsesIcon src={CONSULTING_IMG} width={isMobile ? `30vw` : `30vw`} />)}
      />
      <CategorySection
        leftElement={(
          <Element>
            <CustomText
              text={t(`pages.consulting.consultingServiceTitle`)}
              type="subtitle-with-border"
              className="mb-5"
            />
            <CustomText
              text={t(`pages.consulting.consultingServiceDescription`)}
              type="caption"
              className={isMobile ? `mb-3` : ``}
            />
          </Element>
        )}
        rightElement={(
          <CategoryIcon src={CONSULTING} width={isMobile ? `300px` : `600px`} />)}
      />
      <BenefitsContainer benefits={BENEFITS_OF_CONSULTING} />
      <Contact />
    </ServiceContainer>
  );
}

export default Consulting;
