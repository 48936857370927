// Libs imports
import React, { useContext } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

// context
import { store } from '../../store';

// Styles
import Styles from './Services.styles';

// assets
import WebApps from './assets/aplicaciones-web.svg';
import {
  AWS_IMAGES_PAGE_1,
  BENEFITS_OF_API_REST,
  BENEFITS_OF_MOBILE_APPS,
  BENEFITS_OF_SCRIPTING,
  BENEFITS_OF_WEB_APPS
} from './utils/constants';
import {
  CONST_SOFTWARE,
  DEVELOPMENT_SOFTWARE_IMG,
  MOBILE_APPLICATIONS, RESTFUL_API_AND_MICROSERVICES, SCRIPTING
} from './assets';

// components
import CustomText from '../../components/atoms/CustomText';
import CategorySection from './components/CategorySection/CategorySection';
import BenefitsContainer from './components/BenefitsContainer/BenefitsContainer';
import AWSCertifications from './components/AWSCertifications/AWSCertifications';
import Contact from '../../components/molecules/Contact';
import CategoryWithEllipsesIcon from './components/CategoryWithEllipsesIcon/CategoryWithEllipsesIcon';

const ServiceContainer = styled.div`${Styles.container}`;
const CategoryIcon = styled.img`${Styles.categoryIcon}`;
const ServiceConstellation = styled.img`${Styles.serviceConstellation}`;
const Element = styled.div``;

function Services() {
  const { t } = useTranslation();
  const { isMobile } = useContext(store);

  return (
    <ServiceContainer>
      <ServiceConstellation
        style={{
          marginLeft: isMobile ? `30px` : `12.9vw`,
          marginTop: isMobile ? `50px` : `8vw`
        }}
        src={CONST_SOFTWARE}
      />
      <CategorySection
        withEllipses
        leftElement={isMobile ? (
          <Element className="mb-5">
            <CustomText
              text={t(`pages.services.softwareDev`)}
              type="title"
              className="mb-5"
            />
            <CategoryWithEllipsesIcon src={DEVELOPMENT_SOFTWARE_IMG} width={isMobile ? `300px` : `30vw`} />
          </Element>
        ) : (
          <Element>
            <CustomText
              text={t(`pages.services.softwareDev`)}
              type="title"
              className="mb-5"
            />
            <CustomText
              text={t(`pages.services.softwareDevDescription`)}
              type="caption"
            />
          </Element>
        )}
        rightElement={isMobile ? (
          <CustomText
            text={t(`pages.services.softwareDevDescription`)}
            type="caption"
          />
        ) : (<CategoryWithEllipsesIcon src={DEVELOPMENT_SOFTWARE_IMG} width={isMobile ? `30vw` : `30vw`} />)}
      />
      <CategorySection
        leftElement={(
          <Element>
            <CustomText
              text={t(`pages.services.webApp`)}
              type="subtitle-with-border"
              className="mb-5"
            />
            <CustomText
              text={t(`pages.services.webAppDescription`)}
              type="caption"
              className={isMobile ? `mb-3` : ``}
            />
          </Element>
        )}
        rightElement={(<CategoryIcon src={WebApps} width={isMobile ? `300px` : `600px`} />)}
      />
      <BenefitsContainer benefits={BENEFITS_OF_WEB_APPS} />
      <CategorySection
        leftElement={(<CategoryIcon src={MOBILE_APPLICATIONS} width={isMobile ? `300px` : `600px`} />)}
        rightElement={(
          <Element className={isMobile ? `mt-3` : ``}>
            <CustomText
              text={t(`pages.services.mobileApps`)}
              type="subtitle-with-border"
              className="mb-5"
            />
            <CustomText
              text={t(`pages.services.mobileAppsDescription`)}
              type="caption"
            />
          </Element>
        )}
      />
      <BenefitsContainer benefits={BENEFITS_OF_MOBILE_APPS} />
      <CategorySection
        leftElement={(
          <Element>
            <CustomText
              text={t(`pages.services.restApi`)}
              type="subtitle-with-border"
              className="mb-5"
            />
            <CustomText
              text={t(`pages.services.restApiDescription`)}
              type="caption"
              className={isMobile ? `mb-3` : ``}
            />
          </Element>
        )}
        rightElement={(<CategoryIcon src={RESTFUL_API_AND_MICROSERVICES} width={isMobile ? `300px` : `600px`} />)}
      />
      <BenefitsContainer benefits={BENEFITS_OF_API_REST} />
      <CategorySection
        leftElement={(<CategoryIcon src={SCRIPTING} width={isMobile ? `300px` : `600px`} />)}
        rightElement={(
          <Element className={isMobile ? `mt-3` : ``}>
            <CustomText
              text={t(`pages.services.scripting`)}
              type="subtitle-with-border"
              className="mb-5"
            />
            <CustomText
              text={t(`pages.services.scriptingDescription`)}
              type="caption"
            />
          </Element>
        )}
      />
      <BenefitsContainer benefits={BENEFITS_OF_SCRIPTING} />
      <AWSCertifications certifications={AWS_IMAGES_PAGE_1} />
      <Contact />
    </ServiceContainer>
  );
}

export default Services;
