import phoneActive from '../../../assets/icons/phoneActive.svg';
import phoneInactive from '../../../assets/icons/phoneInactive.svg';
import mailActive from '../../../assets/icons/mailActive.svg';
import mailInactive from '../../../assets/icons/mailInactive.svg';
import careersActive from '../../../assets/icons/careersActive.svg';
import careersInactive from '../../../assets/icons/careersInactive.svg';
import fileActive from '../../../assets/icons/fileActive.svg';
import fileInactive from '../../../assets/icons/fileInactive.svg';

export default {
  phone: {
    activeIcon: phoneActive,
    inactiveIcon: phoneInactive,
    onClick: (e) => {
      e.preventDefault();
      window.open(`tel:3318223565`, `_blank`);
    },
    labelKey: `components.contactButtons.phoneButtonLabel`
  },
  email: {
    activeIcon: mailActive,
    inactiveIcon: mailInactive,
    onClick: (e) => {
      e.preventDefault();
      window.open(`mailto:contacto@cignuz.com`, `_blank`);
    },
    labelKey: `components.contactButtons.mailButtonLabel`
  },
  careers: {
    activeIcon: careersActive,
    inactiveIcon: careersInactive,
    onClick: (e) => {
      e.preventDefault();
      window.open(`mailto:contrataciones@cignuz.com`, `_blank`);
    },
    labelKey: `components.contactButtons.careersButtonLabel`
  },
  file: {
    activeIcon: fileActive,
    inactiveIcon: fileInactive
  }
};
