export default {
  container: {
    position: `relative`,
    display: `flex`,
    flexDirection: `column`,
    marginTop: `80px`,
    width: `100%`,
    maxWidth: `100%`
  },
  cardsContainer: {
    display: `flex`,
    width: `100%`,
    justifyContent: `space-around`,
    alignItems: `center`,
    marginTop: `2.48vw`,
    marginBottom: `2.48vw`
  }
};
