// Libs imports
import React, { useContext } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

// context
import { store } from '../../../../store';

// Styles
import Styles from './StageContainer.styles';

// components
import CustomText from '../../../../components/atoms/CustomText';

const StageContainer = styled.div`${Styles.stageContainer}`;
const TextContainer = styled(Col)`${Styles.textContainer}`;

function StageSection({ stages, generalDescription }) {
  const { t } = useTranslation();
  const { isMobile } = useContext(store);

  return (
    <StageContainer
      style={{
        paddingLeft: isMobile ? `30px` : `12.9vw`,
        paddingRight: isMobile ? `30px` : `10.5vw`
      }}
    >
      <CustomText
        text={t(`pages.infrastructure.stageTitle`)}
        type="subtitle"
        size={35}
      />
      <Row className="d-flex justify-content-between">
        {stages.map((item, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <TextContainer key={index} style={{ maxWidth: isMobile ? `100%` : `350px` }} xs={12} md="auto">
            <CustomText
              text={`0${index + 1}`}
              type="caption-bold-grey"
              className="mb-3"
            />
            <CustomText
              text={t(item.title)}
              type="subtitle"
              lineHeight={1}
              size={25}
            />
            <CustomText
              text={t(item.description)}
              marginTop={0}
              type="card-small-description"
              size={16}
              textAlign="left"
            />
          </TextContainer>
        ))}
      </Row>
      {generalDescription && (
        <CustomText
          text={t(generalDescription)}
          marginTop="3%"
          type="card-small-description"
          size={16}
          textAlign="left"
        />
      )}
    </StageContainer>
  );
}

export default StageSection;
