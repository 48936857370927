export default {
  container: {
    display: `flex`,
    flexDirection: `column`
  },
  titleContainer: {
    display: `flex`,
    flexDirection: `row`,
    alignItems: `center`,
    paddingLeft: `4.25vw`,
    maxWidth: `100%`,
    borderLeftColor: `#FFD525`,
    borderLeftWidth: `max(1vw, 20px)`,
    borderLeftStyle: `solid`,
    marginLeft: `0.1vw`,
    marginRight: 0,
    marginBottom: `1vw`
  },
  cignuzLogo: {
    height: `max(2.44vw, 39.23px)`,
    marginRight: 15
  }
};
