import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import Col from 'react-bootstrap/Col';

// context
import { store } from '../../../store';

// styles
import Styles from './CardWithDescription.styles';

// components
import CustomText from '../CustomText';

const CardContainer = styled(Col)`${Styles.container}`;
const CardInner = styled.a`${Styles.inner}`;
const Image = styled.img`${Styles.img}`;

function CardWithDescription({
  image,
  imageHover,
  title,
  description,
  onClick
}) {
  const [hover, setHover] = useState(false);

  const { isMobile } = useContext(store);

  const toggleHover = () => {
    setHover(!hover);
  };

  return (
    <CardContainer className="p-3 col-12 col-md-6 col-lg-4">
      <CardInner
        onMouseEnter={toggleHover}
        onMouseLeave={toggleHover}
        onClick={onClick}
        style={{
          boxShadow: (hover || isMobile) && `0px 20px 99px #00000025`,
          transition: `box-shadow 0.3s ease-in-out`
        }}
      >
        <Image src={(hover || isMobile) ? imageHover : image} />
        <CustomText text={title} type="card-small-title" />
        <CustomText text={description} type="card-small-description" />
      </CardInner>
    </CardContainer>
  );
}

export default CardWithDescription;
