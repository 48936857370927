import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

// styles
import Styles from './Hero.styles';

// assets
import CignuzLogo from '../../../../assets/logoCignuz.svg';
import YellowCircle from '../../../../assets/yellowCircle.png';
import Constellation1 from '../../../../assets/constellation/constellation-moment-1.svg';
import Constellation2 from '../../../../assets/constellation/constellation-moment-2.svg';
import Constellation3 from '../../../../assets/constellation/constellation-moment-3.svg';
import Constellation4 from '../../../../assets/constellation/constellation-moment-4.svg';

// context
import { store } from '../../../../store';

// components
import CustomText from '../../../../components/atoms/CustomText';

// Styled components
const HeroContainer = styled.div`${Styles.heroContainer}`;
const CignuzLogoWrapper = styled.img`${Styles.cignuzLogoWrapper}`;
const CignuzText = styled.small`${Styles.cignuzText}`;

// Animated Hero Elements
const Sequenced1 = styled(CustomText)`${Styles.sequencedHeroElement} ${Styles.sequenced1}`;
const Sequenced2 = styled(CustomText)`${Styles.sequencedHeroElement} ${Styles.sequenced2}`;
const Sequenced3 = styled(CustomText)`${Styles.sequencedHeroElement} ${Styles.sequenced3}`;
const Sequenced4 = styled(CustomText)`${Styles.sequencedHeroElement} ${Styles.sequenced4}`;
const Sequenced5 = styled.div`${Styles.sequencedHeroElement} ${Styles.sequenced5}`;
const SequencedImg1 = styled.img`${Styles.constellation} ${Styles.sequencedHeroElement} ${Styles.sequencedImg1}`;
const SequencedImg2 = styled.img`${Styles.constellation} ${Styles.sequencedHeroElement} ${Styles.sequencedImg2}`;
const SequencedImg3 = styled.img`${Styles.constellation} ${Styles.sequencedHeroElement} ${Styles.sequencedImg3}`;
const SequencedImg4 = styled.img`${Styles.constellation} ${Styles.sequencedHeroElement} ${Styles.sequencedImg4}`;

// Landscape Ellipses
const BigCircle = styled.img`${Styles.bigCircle}`;
const TopHalfCircle = styled.img`${Styles.topHalfCircle}`;
const SmallCircle = styled.img`${Styles.smallCircle}`;

// Portrait Ellipses
const RightCircle = styled.img`${Styles.rightCircle}`;
const LeftCircle = styled.img`${Styles.leftCircle}`;

function LandscapeEllipses() {
  return (
    <>
      <TopHalfCircle className="d-none d-lg-flex" src={YellowCircle} />
      <BigCircle className="d-none d-lg-flex" src={YellowCircle} />
      <SmallCircle className="d-none d-lg-flex" src={YellowCircle} />
    </>
  );
}

function PortraitEllipses() {
  return (
    <>
      <RightCircle className="d-flex d-lg-none" src={YellowCircle} />
      <LeftCircle className="d-flex d-lg-none" src={YellowCircle} />
    </>
  );
}

function Hero() {
  const { isMobile } = React.useContext(store);
  const { t } = useTranslation();

  return (
    <HeroContainer>
      <SequencedImg1 src={Constellation1} className="mr-5 mb-5" />
      <SequencedImg2 src={Constellation2} className="mr-5 mb-5" />
      <SequencedImg3 src={Constellation3} className="mr-5 mb-5" />
      <SequencedImg4 src={Constellation4} className="mr-5 mb-5" />
      <Sequenced1 text={t(`pages.home.sequenced.text1`)} type="title" letterSpacing="10px" opacity="0" size={isMobile ? `8vw` : `max(4.79vw, 60px)`} />
      <Sequenced2 text={t(`pages.home.sequenced.text2`)} type="title" letterSpacing="10px" opacity="0" size={isMobile ? `8vw` : `max(4.79vw, 60px)`} />
      <Sequenced3 text={t(`pages.home.sequenced.text3`)} type="title" letterSpacing="10px" opacity="0" size={isMobile ? `8vw` : `max(4.79vw, 60px)`} />
      <Sequenced4 text={t(`pages.home.sequenced.text4`)} type="title" letterSpacing="10px" opacity="0" size={isMobile ? `8vw` : `max(4.79vw, 60px)`} />
      <Sequenced5>
        <CignuzLogoWrapper src={CignuzLogo} />
        <CignuzText>
          Soluciones Tecnológicas
        </CignuzText>
      </Sequenced5>
      <LandscapeEllipses />
      <PortraitEllipses />
    </HeroContainer>
  );
}

export default Hero;
