export default {
  heroContainer: {
    display: `flex`,
    flexDirection: `column`,
    position: `relative`,
    width: `100%`,
    alignItems: `center`,
    justifyContent: `flex-start`,
    backgroundColor: `#FFFFFF`,
    overflow: `hidden`,
    paddingTop: `max(6.61vw, 88px)`,
    paddingBottom: `max(6.61vw, 88px)`
  },
  topEllipsis: {
    height: `18rem`,
    position: `absolute`,
    top: `-12rem`,
    opacity: 0.2,
    filter: `blur(50px)`,
    '-webkit-backdrop-filter': `blur(50px);`
  },
  leftEllipsis: {
    height: `3.25rem`,
    position: `absolute`,
    bottom: `5%`,
    left: `3.95vw`,
    opacity: 0.66,
    filter: `blur(30px)`,
    '-webkit-backdrop-filter': `blur(30px);`
  }
};
