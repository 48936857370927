import React from 'react';
import styled from 'styled-components';
import Col from 'react-bootstrap/Col';

// styles
import Styles from './SimpleCardWithImage.styles';

// components
import CustomText from '../CustomText';

const CardContainer = styled(Col)`${Styles.container}`;
const Image = styled.img`${Styles.img}`;

function SimpleCardWithImage({ image, title, subtitle }) {
  return (
    <CardContainer className="col-12 col-md-5">
      <Image src={image} />
      <CustomText text={title} type="title" />
      <CustomText text={subtitle} type="caption-regular" />
    </CardContainer>
  );
}

export default SimpleCardWithImage;
