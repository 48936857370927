export default {
  container: {
    display: `flex`,
    flexDirection: `column`
  },
  innerContainer: {
    borderRadius: `30px`,
    boxShadow: `0px 20px 99px #00000015;`
  }
};
