export default {
  categoryContainer: {
    position: `relative`,
    display: `flex`,
    flexDirection: `column`,
    alignItems: `center`,
    marginTop: `max(4.15vw, 48px)`,
    marginBottom: `max(2vw, 20px)`
  }
};
