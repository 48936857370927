import React, { useContext } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

// context
import { store } from '../../../../store';

// assets
import mailActive from '../../../../assets/icons/mailActive.svg';
import phoneActive from '../../../../assets/icons/phoneActive.svg';
import cellphoneActive from '../../../../assets/icons/cellphoneActive.svg';

// styles
import Styles from './ContactUs.styles';

// components
import CustomText from '../../../../components/atoms/CustomText';

const Container = styled.div`${Styles.container}`;
const Button = styled.a`${Styles.button}`;
const Icon = styled.img`${Styles.icon}`;
const Inner = styled.div`${Styles.innerButton}`;

function ContactButton({ label, action, icon }) {
  return (
    <Col xs={12} md={4} className="mb-3">
      <Button onClick={action}>
        <Inner>
          <Icon src={icon} />
          <CustomText text={label} type="caption-bold" size="15px" textAlign="center" />
        </Inner>
      </Button>
    </Col>
  );
}

function ContactUs() {
  const { t } = useTranslation();
  const { isMobile } = useContext(store);

  return (
    <Container
      style={{
        paddingLeft: isMobile ? `30px` : `19vw`,
        paddingRight: isMobile ? `30px` : `15vw`,
        paddingTop: isMobile ? `43px` : `4.63vw`,
        paddingBottom: isMobile ? `28px` : `2.94vw`
      }}
    >
      <CustomText
        text={t(`pages.policies.contactUs.smallTitle`)}
        type="caption-light-letter-spacing"
        textAlign="center"
      />
      <CustomText
        text={t(`pages.policies.contactUs.title`)}
        textAlign="center"
        type="subtitle"
        lineHeight="53px"
        className="mb-5"
      />
      <CustomText text={t(`pages.policies.contactUs.paragraph1`)} className="mb-5" />
      <CustomText text={t(`pages.policies.contactUs.paragraph2`)} className="mb-5" />
      <Row>
        <ContactButton
          label="denuncia.anonima@cignuz.com"
          icon={mailActive}
          action={(e) => {
            e.preventDefault();
            window.open(`mailto:denuncia.anonima@cignuz.com`, `_blank`);
          }}
        />
        <ContactButton
          label="33 31 48 66 24"
          icon={phoneActive}
          action={(e) => {
            e.preventDefault();
            window.open(`tel:3331486624`, `_blank`);
          }}
        />
        <ContactButton
          label="33 43 04 02 40"
          icon={cellphoneActive}
          action={(e) => {
            e.preventDefault();
            window.open(`https://wa.me/+523343040240`, `_blank`);
          }}
        />
      </Row>
    </Container>
  );
}

export default ContactUs;
