// Libs Imports
import React from 'react';
import {
  BrowserRouter, Routes, Route, Outlet, Navigate
} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ThemeProvider } from 'react-bootstrap';

// context
import { ContextProvider } from './store';

// Pages
import Home from './pages/Home';
import Services from './pages/Services';
// eslint-disable-next-line import/no-unresolved
import Infrastructure from './pages/Services/pages/Infrastructure/Infrastructure';
import AboutUs from './pages/AboutUs';
import Policy from './pages/Policy';
import Contact from './pages/Contact';
// import NoPage from './pages/NoPage';

// Components
import LanguagePath from './components/LanguagePath';
import ScrollToTop from './components/ScrollToTop';
import Header from './components/Header';
import Footer from './components/Footer';

// Styles
import './App.css';
import Telecommunication from './pages/Services/pages/Telecommunication/Telecommunication';
import Security from './pages/Services/pages/Security/Security';
import Design from './pages/Services/pages/Design/Design';
import Consulting from './pages/Services/pages/Consulting/Consulting';
import Projects from './pages/Projects';

function App() {
  const { t } = useTranslation();
  return (
    <ContextProvider>
      <ThemeProvider>
        <BrowserRouter>
          <Header />
          <Routes>
            <Route path="/" element={<LanguagePath />}>
              <Route path=":lang">
                <Route path="" element={<Home />} />
                <Route path={t(`routes.services`)} element={<Outlet />}>
                  <Route index element={<Services />} />
                  <Route path={t(`routes.subServices.softwareDev`)} element={<Services />} />
                  <Route path={t(`routes.subServices.infrastructure`)} element={<Infrastructure />} />
                  <Route path={t(`routes.subServices.telecommunication`)} element={<Telecommunication />} />
                  <Route path={t(`routes.subServices.security`)} element={<Security />} />
                  <Route path={t(`routes.subServices.design`)} element={<Design />} />
                  <Route path={t(`routes.subServices.consultancy`)} element={<Consulting />} />
                </Route>
                <Route path={t(`routes.aboutUs`)} element={<AboutUs />} />
                <Route path={t(`routes.policies`)} element={<Policy />} />
                <Route path={t(`routes.projects`)} element={<Projects />} />
                <Route path={t(`routes.contact`)} element={<Contact />} />
                <Route path="*" element={<Navigate to="/" replace />} />
              </Route>
            </Route>
          </Routes>
          <Footer />
          <ScrollToTop />
        </BrowserRouter>
      </ThemeProvider>
    </ContextProvider>
  );
}

export default App;
