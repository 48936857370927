export default {
  container: {
    backgroundColor: `#F7F7F7`,
    display: `flex`,
    flexDirection: `column`
  },
  innerButton: {
    backgroundColor: `#FFFFFF`,
    borderRadius: `30px`,
    boxShadow: `0px 20px 99px #00000015;`,
    display: `flex`,
    justifyContent: `center`,
    flexDirection: `column`,
    paddingTop: `27px`,
    paddingBottom: `27px`,
    cursor: `pointer`
  },
  icon: {
    height: `42px`
  }
};
