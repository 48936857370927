export default {
  heroContainer: {
    display: `flex`,
    flexDirection: `column`,
    position: `relative`,
    width: `100%`,
    height: `100vh`,
    alignItems: `center`,
    justifyContent: `center`,
    backgroundColor: `#FFFFFF`,
    zIndex: 14,
    overflow: `hidden`
  },
  cignuzLogoWrapper: {
    height: `6.9rem`,
    maxWidth: `100%`,
    zIndex: 15
  },
  cignuzText: {
    fontFamily: `geologica-extra-light`,
    fontSize: `max(1vw, 12px)`,
    lineHeight: `max(1.25vw, 15px)`,
    letterSpacing: `2px`,
    color: `#242421`,
    marginTop: `max(1.5vw, 20px)`
  },
  constellation: {
    position: `absolute`,
    height: `max(33.6vw, 300px)`,
    opacity: 0,
    filter: `blur(4px)`,
    '-webkit-backdrop-filter': `blur(40px);`
  },
  bigCircle: {
    height: `27rem`,
    position: `absolute`,
    top: `50%`,
    right: `8%`,
    opacity: 0,
    filter: `blur(50px)`,
    '-webkit-backdrop-filter': `blur(50px);`,
    animation: `fadeIn20 1s ease-in-out forwards 0s`
  },
  topHalfCircle: {
    height: `9rem`,
    position: `absolute`,
    top: `-4.5rem`,
    left: `27%`,
    opacity: 0,
    filter: `blur(50px)`,
    '-webkit-backdrop-filter': `blur(50px);`,
    animation: `fadeIn20 1s ease-in-out forwards 3s`
  },
  smallCircle: {
    height: `3.25rem`,
    position: `absolute`,
    top: `49%`,
    left: `11%`,
    opacity: 0,
    filter: `blur(30px)`,
    '-webkit-backdrop-filter': `blur(30px);`,
    animation: `fadeIn20 1s ease-in-out forwards 3s`
  },
  rightCircle: {
    height: `27rem`,
    position: `absolute`,
    top: `50%`,
    right: `-70%`,
    opacity: 0.2,
    filter: `blur(50px)`,
    '-webkit-backdrop-filter': `blur(50px);`,
    animation: `fadeIn20 1s ease-in-out forwards 0s`
  },
  leftCircle: {
    height: `3.25rem`,
    position: `absolute`,
    top: `17%`,
    left: `-5%`,
    opacity: 0.66,
    filter: `blur(30px)`,
    '-webkit-backdrop-filter': `blur(30px);`,
    animation: `fadeIn20 1s ease-in-out forwards 3s`
  },
  sequencedHeroElement: {
    position: `absolute`,
    left: `50%`,
    top: `45%`,
    transform: `translate(-50%, -50%)`,
    textAlign: `center`
  },
  sequenced1: {
    animation: `fadeInOut 3s ease-in-out forwards 0s`
  },
  sequenced2: {
    animation: `fadeInOut 3s ease-in-out forwards 3s`
  },
  sequenced3: {
    animation: `fadeInOut 3s ease-in-out forwards 6s`
  },
  sequenced4: {
    animation: `fadeInOut 3s ease-in-out forwards 9s`
  },
  sequenced5: {
    opacity: 0,
    animation: `fadeIn 2s linear forwards 12.5s`,
    display: `flex`,
    flexDirection: `column`,
    alignItems: `center`,
    '@media (max-width: 992px)': {
      width: `90%`
    }
  },
  sequencedImg1: {
    animation: `fadeInOut50 4s ease-in-out forwards 0s`
  },
  sequencedImg2: {
    animation: `fadeInOut50 4s ease-in-out forwards 3s`
  },
  sequencedImg3: {
    animation: `fadeInOut50 4s ease-in-out forwards 6s`
  },
  sequencedImg4: {
    animation: `fadeIn50 1s ease-in-out forwards 9s`
  }
};
