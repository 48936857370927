export default {
  button: {
    display: `flex`,
    alignItems: `center`,
    justifyContent: `center`,
    cursor: `pointer`,
    width: `100%`,
    minHeight: `104px`,
    marginTop: `1.2rem`,
    marginBottom: `1.2rem`,
    borderStyle: `solid`,
    borderWidth: `1px`,
    borderColor: `#242421`,
    borderRadius: `20px`,
    maxWidth: (props) => (props.maxWidth || `min(29vw, 557px)`),
    '@media (max-width: 992px)': {
      maxWidth: `100%`
    },
    '&:hover': {
      boxShadow: `0px 3px 99px #00000015`
    }
  },
  icon: {
    width: `40px`,
    marginRight: `20px`
  }
};
