// Libs imports
import React, { useContext } from 'react';
import styled from 'styled-components';
import Row from 'react-bootstrap/Row';
import { useTranslation } from 'react-i18next';

// context
import { store } from '../../store';

// Styles
import Styles from './AboutUs.styles';

// components
import Hero from './components/Hero';
import Intro from './components/Intro';
import NameOrigin from './components/NameOrigin';
import CardWithColor from './components/CardWithColor';
import Values from './components/Values';
import MissionAndVision from './components/MissionAndVision';
import Achievements from './components/Achievements';

const Container = styled.div`${Styles.container}`;
const CardsContainer = styled(Row)`${Styles.cardsContainer}`;

function AboutUs() {
  const { t } = useTranslation();
  const { isMobile } = useContext(store);
  return (
    <Container>
      <Hero />
      <Intro />
      <NameOrigin />
      <CardsContainer
        style={{
          paddingLeft: isMobile ? `30px` : `10vw`,
          paddingRight: isMobile ? `30px` : `10vw`
        }}
        className="ml-0 mr-0"
      >
        <CardWithColor
          title={t(`pages.aboutUs.clients.title`)}
          subtitle={t(`pages.aboutUs.clients.subtitle`)}
          description={t(`pages.aboutUs.clients.description`)}
          color="#F7F7F7"
          subtitleColor="#ADADAD"
        />
        <CardWithColor
          title={t(`pages.aboutUs.collaborators.title`)}
          subtitle={t(`pages.aboutUs.collaborators.subtitle`)}
          description={t(`pages.aboutUs.collaborators.description`)}
          color="#FFFCEF"
          subtitleColor="#EBB42A"
        />
      </CardsContainer>
      <Values />
      <MissionAndVision />
      <Achievements />
    </Container>
  );
}

export default AboutUs;
