export default {
  container: {
    position: `relative`,
    display: `flex`,
    flexDirection: `column`,
    width: `100%`,
    alignItems: `center`,
    marginTop: `2.7vw`
  },
  ellipsis: {
    position: `absolute`,
    top: `2vw`,
    right: 0,
    zIndex: -1,
    height: `max(17.5vw, 200px)`,
    opacity: 0.2,
    filter: `blur(50px)`,
    '-webkit-backdrop-filter': `blur(50px);`
  }
};
