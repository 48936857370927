export default {
  container: {
    display: `flex`,
    paddingTop: `max(1.67vw, 40px)`,
    flexDirection: `column`,
    width: `100%`
  },
  mainCard: {
    display: `flex`,
    width: `100%`,
    paddingTop: `15px`,
    paddingBottom: `15px`,
    flexDirection: `column`,
    borderRadius: `30px`,
    backgroundColor: `#FFFFFF`,
    paddingLeft: `15px`,
    paddingRight: `15px`,
    boxShadow: `0px 20px 99px #00000015`,
    '@media (max-width: 992px)': {
      backgroundColor: `transparent`,
      paddingLeft: `0px`,
      paddingRight: `0px`,
      boxShadow: `none`
    }
  },
  titleContainer: {
    paddingLeft: `1vw`,
    borderLeftColor: `#FFD525`,
    borderLeftWidth: `max(1vw, 20px)`,
    borderLeftStyle: `solid`,
    marginLeft: `1.5vw`,
    marginBottom: `1vw`,
    marginTop: `1vw`,
    '@media (max-width: 992px)': {
      paddingLeft: `4vw`,
      marginLeft: `0.1vw`
    }
  },
  img: {
    display: `flex`,
    height: `max(5.5vw, 100px)`,
    objectFit: `contain`,
    alignSelf: `flex-start`
  },
  col: {
    display: `flex`,
    flexDirection: `column`,
    justifyContent: `center`,
    alignItems: `center`
  },
  mapContainer: {
    display: `flex`,
    height: `100%`,
    '@media (max-width: 992px)': {
      height: `max(100vw, 300px)`
    }
  }
};
