export default {
  headerContainer: {
    display: `flex`,
    position: `fixed`,
    width: `100%`,
    height: `80px`,
    top: 0,
    zIndex: 10,
    flexDirection: `row`,
    justifyContent: `space-between`,
    alignItems: `center`,
    backgroundColor: `rgba(247, 247, 247, 0.5)`,
    backdropFilter: `blur(17px)`,
    '-webkit-backdrop-filter': `blur(17px);`
  },
  logoWrapper: {
    cursor: `pointer`
  },
  logo: {
    height: `30px`
  },
  optionsContainer: {
    display: `flex`,
    flexDirection: `row`
  },
  optionWrapper: {
    display: `flex`,
    alignItems: `center`,
    cursor: `pointer`
  },
  optionIcon: {
    height: `30px`
  },
  optionLabel: {
    marginLeft: `13px`
  },
  responsiveOptsContainer: {
    display: `flex`,
    justifyContent: `space-between`
  },
  menuToggleContainer: {
    display: `flex`,
    cursor: `pointer`
  },
  menuToggle: {
    height: `30px`
  }
};
