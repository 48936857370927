export default {
  stageContainer: {
    display: `flex`,
    flexDirection: `column`,
    width: `100%`,
    marginBottom: `max(2.7vw, 40px)`,
    marginTop: `max(2.7vw, 40px)`
  },
  textContainer: {
    paddingTop: `max(1.67vw, 20px)`
  },
  generalDescription: {
  }
};
