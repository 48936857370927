import {
  ACCESSIBILITY, AUTOMATION, AWS_ARCHITECT, AWS_ASSOCIATE, AWS_DATABASE_SPECIALTY, AWS_ISTQB, AWS_PRACTITIONER, CENTRALIZATION, COMMUNICATION, COST_TIME, DATA_COLLECTION, EFFICIENCY, FOCUS, INFRASTRUCTURE, INTEGRATION, PERSONALIZED_EXPERIENCE, PORTABILITY, PROCESS_OPTIMIZATION, RELIABILITY, RESILIENCE, SCALABILITY,
  SECURITY,
  SYNC,
  USER_EXPERIENCE,
  OPERATIONAL_COST,
  PRODUCTIVITY,
  INNOVATION,
  SECURITY_CLOUD,
  PERFORMANCE,
  BEST_PRACTICES,
  RECOMMENDATIONS,
  RESULTS,
  APP_PERFORMANCE,
  METRIC,
  IDENTIFY_RISKS,
  CONFIDENCE,
  TELEPHONE_INTEGRATION,
  COST_REDUCTION,
  BACKUPS,
  CUSTOMER_SATISFACTION,
  INTERCONNECTION_SERVICES,
  CONFIGURATION,
  USER_MONITORING,
  CUSTOMIZED_DESIGN,
  REDUCE_TIME,
  TELEPHONY_AVAILABILITY,
  CEH_09,
  CEH_MASTER_10,
  CEH_PROFESSIONAL_11,
  THREATS,
  TASK_AUTOMATION,
  CUSTOMER_KNOWLEDGE,
  MANAGEMENT_TOOLS,
  SECURITY_THREATS,
  SECURITY_OPTIMIZATION,
  ATTACK,
  CORPORATE_SECURITY,
  INFORMATION_ACCESS_ATTACK,
  SERVICE_CONTINUITY,
  NO_AUTHORIZED,
  DATA_LEAKAGE,
  BRANDING,
  DESIGN,
  REDUCE_FRICTION,
  TRUST_SECURITY,
  METRICS,
  ICO_REDUCE_TIME,
  DATA_BASE,
  PERFORMANCE_2,
  COST_REDUCTION_2,
  ICO_UX,
  RESPECT,
  SECURITY_OPTIMIZATION_2
} from "../assets";

export const BENEFITS_OF_WEB_APPS = [{
  icon: ACCESSIBILITY,
  text: `pages.services.webAppBenefit1`
},
{
  icon: SYNC,
  text: `pages.services.webAppBenefit2`
},
{
  icon: SCALABILITY,
  text: `pages.services.webAppBenefit3`
},
{
  icon: SECURITY,
  text: `pages.services.webAppBenefit4`
},
{
  icon: EFFICIENCY,
  text: `pages.services.webAppBenefit5`
}];

export const BENEFITS_OF_MOBILE_APPS = [{
  icon: PORTABILITY,
  text: `pages.services.mobileAppsBenefit1`
},
{
  icon: COMMUNICATION,
  text: `pages.services.mobileAppsBenefit2`
},
{
  icon: USER_EXPERIENCE,
  text: `pages.services.mobileAppsBenefit3`
},
{
  icon: PERSONALIZED_EXPERIENCE,
  text: `pages.services.mobileAppsBenefit4`
},
{
  icon: DATA_COLLECTION,
  text: `pages.services.mobileAppsBenefit5`
}];

export const BENEFITS_OF_API_REST = [{
  icon: INFRASTRUCTURE,
  text: `pages.services.apiRestBenefit1`
},
{
  icon: RESILIENCE,
  text: `pages.services.apiRestBenefit2`
},
{
  icon: CENTRALIZATION,
  text: `pages.services.apiRestBenefit3`
},
{
  icon: INTEGRATION,
  text: `pages.services.apiRestBenefit4`
},
{
  icon: COST_TIME,
  text: `pages.services.apiRestBenefit5`
}];

export const BENEFITS_OF_SCRIPTING = [{
  icon: AUTOMATION,
  text: `pages.services.scriptingBenefit1`
},
{
  icon: FOCUS,
  text: `pages.services.scriptingBenefit2`
},
{
  icon: RELIABILITY,
  text: `pages.services.scriptingBenefit3`
},
{
  icon: PROCESS_OPTIMIZATION,
  text: `pages.services.scriptingBenefit4`
}];

export const AWS_IMAGES_PAGE_1 = [
  { id: 1, src: AWS_ASSOCIATE, alt: `Image 1` },
  { id: 2, src: AWS_PRACTITIONER, alt: `Image 2` },
  { id: 3, src: AWS_DATABASE_SPECIALTY, alt: `Image 3` },
  { id: 4, src: AWS_ISTQB, alt: `Image 4` },
  { id: 5, src: AWS_ARCHITECT, alt: `Image 5` }
];

export const MIGRATION_STAGES = [{
  title: `pages.infrastructure.stageTitle1`,
  description: `pages.infrastructure.stageDescription1`
},
{
  title: `pages.infrastructure.stageTitle2`,
  description: `pages.infrastructure.stageDescription2`
},
{
  title: `pages.infrastructure.stageTitle3`,
  description: `pages.infrastructure.stageDescription3`
}];

export const BENEFITS_OF_WORKLOAD_MIGRATION = [{
  icon: OPERATIONAL_COST,
  text: `pages.infrastructure.workloadBenefit1`
},
{
  icon: PRODUCTIVITY,
  text: `pages.infrastructure.workloadBenefit2`
},
{
  icon: INNOVATION,
  text: `pages.infrastructure.workloadBenefit3`
},
{
  icon: SECURITY_CLOUD,
  text: `pages.infrastructure.workloadBenefit4`
}];

export const STAGES_OF_APPLICATION_MODERNIZATION = [{
  title: `pages.infrastructure.modernStageTitle1`,
  description: `pages.infrastructure.modernStageDescription1`
},
{
  title: `pages.infrastructure.modernStageTitle2`,
  description: `pages.infrastructure.modernStageDescription2`
},
{
  title: `pages.infrastructure.modernStageTitle3`,
  description: `pages.infrastructure.modernStageDescription3`
}];

export const BENEFITS_OF_APP_MODERNIZATION = [{
  icon: PERFORMANCE,
  text: `pages.infrastructure.modernizationBenefit1`
},
{
  icon: RECOMMENDATIONS,
  text: `pages.infrastructure.modernizationBenefit2`
},
{
  icon: BEST_PRACTICES,
  text: `pages.infrastructure.modernizationBenefit3`
},
{
  icon: OPERATIONAL_COST,
  text: `pages.infrastructure.modernizationBenefit4`
}];

export const BENEFITS_OF_CLOUD_WORKLOAD = [{
  icon: RESULTS,
  text: `pages.infrastructure.cloudWorkloadBenefit1`
},
{
  icon: USER_EXPERIENCE,
  text: `pages.infrastructure.cloudWorkloadBenefit2`
},
{
  icon: APP_PERFORMANCE,
  text: `pages.infrastructure.cloudWorkloadBenefit3`
},
{
  icon: APP_PERFORMANCE,
  text: `pages.infrastructure.cloudWorkloadBenefit4`
}];

export const BENEFITS_OF_SOFT_OPTIMIZATION = [{
  icon: METRICS,
  text: `pages.infrastructure.softOptimizationBenefit1`
},
{
  icon: ICO_REDUCE_TIME,
  text: `pages.infrastructure.softOptimizationBenefit2`
},
{
  icon: TRUST_SECURITY,
  text: `pages.infrastructure.softOptimizationBenefit3`
},
{
  icon: REDUCE_FRICTION,
  text: `pages.infrastructure.softOptimizationBenefit4`
}];

export const BENEFITS_OF_CONSULT_ARCH = [{
  icon: SECURITY,
  text: `pages.infrastructure.consultArchBenefit1`
},
{
  icon: CONFIDENCE,
  text: `pages.infrastructure.consultArchBenefit2`
},
{
  icon: RECOMMENDATIONS,
  text: `pages.infrastructure.consultArchBenefit3`
},
{
  icon: IDENTIFY_RISKS,
  text: `pages.infrastructure.consultArchBenefit4`
},
{
  icon: EFFICIENCY,
  text: `pages.infrastructure.consultArchBenefit5`
}];

export const STAGES_OF_CONSULT_ARCH = [{
  title: `pages.infrastructure.consultArchStageTitle1`,
  description: `pages.infrastructure.consultArchStageDescription1`
},
{
  title: `pages.infrastructure.consultArchStageTitle2`,
  description: `pages.infrastructure.consultArchStageDescription2`
},
{
  title: `pages.infrastructure.consultArchStageTitle3`,
  description: `pages.infrastructure.consultArchStageDescription3`
},
{
  title: `pages.infrastructure.consultArchStageTitle4`,
  description: `pages.infrastructure.consultArchStageDescription4`
}];

export const BENEFITS_OF_DATA_LAKE = [{
  icon: METRIC,
  text: `pages.infrastructure.dataLakeBenefit1`
},
{
  icon: CENTRALIZATION,
  text: `pages.infrastructure.dataLakeBenefit2`
}];

export const BENEFITS_OF_SWITCH_MAINTENANCE = [{
  icon: COST_REDUCTION,
  text: `pages.telecommunication.switchMaintenanceBenefit1`
},
{
  icon: SECURITY,
  text: `pages.telecommunication.switchMaintenanceBenefit2`
},
{
  icon: BACKUPS,
  text: `pages.telecommunication.switchMaintenanceBenefit3`
},
{
  icon: TELEPHONE_INTEGRATION,
  text: `pages.telecommunication.switchMaintenanceBenefit4`
}];

export const BENEFITS_OF_CALL_CENTER = [{
  icon: INTERCONNECTION_SERVICES,
  text: `pages.telecommunication.callCenterBenefit1`
},
{
  icon: CONFIGURATION,
  text: `pages.telecommunication.callCenterBenefit2`
},
{
  icon: CUSTOMER_SATISFACTION,
  text: `pages.telecommunication.callCenterBenefit3`
},
{
  icon: CENTRALIZATION,
  text: `pages.telecommunication.callCenterBenefit4`
}];

export const BENEFITS_OF_PHONE_APPS = [{
  icon: CUSTOMIZED_DESIGN,
  text: `pages.telecommunication.phoneAppsBenefit1`
},
{
  icon: INTEGRATION,
  text: `pages.telecommunication.phoneAppsBenefit2`
},
{
  icon: USER_MONITORING,
  text: `pages.telecommunication.phoneAppsBenefit3`
},
{
  icon: TASK_AUTOMATION,
  text: `pages.telecommunication.phoneAppsBenefit4`
}];

export const BENEFITS_OF_SURVEY = [{
  icon: CUSTOMER_KNOWLEDGE,
  text: `pages.telecommunication.surveyBenefit1`
},
{
  icon: REDUCE_TIME,
  text: `pages.telecommunication.surveyBenefit2`
},
{
  icon: COST_REDUCTION,
  text: `pages.telecommunication.surveyBenefit3`
},
{
  icon: MANAGEMENT_TOOLS,
  text: `pages.telecommunication.surveyBenefit4`
},
{
  icon: DATA_BASE,
  text: `pages.telecommunication.surveyBenefit5`
}];

export const BENEFITS_OF_REVIEWS = [{
  icon: TELEPHONY_AVAILABILITY,
  text: `pages.telecommunication.reviewsBenefit1`
},
{
  icon: TELEPHONE_INTEGRATION,
  text: `pages.telecommunication.reviewsBenefit2`
},
{
  icon: PERFORMANCE_2,
  text: `pages.telecommunication.reviewsBenefit3`
},
{
  icon: THREATS,
  text: `pages.telecommunication.reviewsBenefit4`
}];

export const TELECOMMUNICATION_CERTIFICATIONS = [
  { id: 1, src: CEH_09, alt: `CEH_09` },
  { id: 2, src: CEH_MASTER_10, alt: `CEH_MASTER_10` },
  { id: 3, src: CEH_PROFESSIONAL_11, alt: `CEH_PROFESSIONAL_11` }
];

export const BENEFITS_OF_BEST_PRACTICE = [{
  icon: NO_AUTHORIZED,
  text: `pages.security.bestPracticesBenefit1`
},
{
  icon: INFORMATION_ACCESS_ATTACK,
  text: `pages.security.bestPracticesBenefit2`
},
{
  icon: DATA_LEAKAGE,
  text: `pages.security.bestPracticesBenefit3`
},
{
  icon: SERVICE_CONTINUITY,
  text: `pages.security.bestPracticesBenefit4`
}];

export const BENEFITS_OF_PENTESTING = [
  {
    icon: ATTACK,
    text: `pages.security.pentestingBenefit1`
  },
  {
    icon: COST_REDUCTION_2,
    text: `pages.security.pentestingBenefit2`
  },
  {
    icon: CORPORATE_SECURITY,
    text: `pages.security.pentestingBenefit3`
  },
  {
    icon: INFORMATION_ACCESS_ATTACK,
    text: `pages.security.pentestingBenefit4`
  },
  {
    icon: SECURITY_THREATS,
    text: `pages.security.pentestingBenefit5`
  },
  {
    icon: SECURITY_OPTIMIZATION,
    text: `pages.security.pentestingBenefit6`
  }
];

export const BENEFITS_OF_UX = [{
  icon: DESIGN,
  text: `pages.design.UXBenefit1`
},
{
  icon: ICO_UX,
  text: `pages.design.UXBenefit2`
},
{
  icon: ACCESSIBILITY,
  text: `pages.design.UXBenefit3`
},
{
  icon: BRANDING,
  text: `pages.design.UXBenefit4`
}];

export const BENEFITS_OF_CONSULTING = [{
  icon: COST_REDUCTION,
  text: `pages.consulting.benefit1`
},
{
  icon: SECURITY_OPTIMIZATION_2,
  text: `pages.consulting.benefit2`
},
{
  icon: RESULTS,
  text: `pages.consulting.benefit3`
},
{
  icon: RELIABILITY,
  text: `pages.consulting.benefit4`
},
{
  icon: RESPECT,
  text: `pages.consulting.benefit5`
},
{
  icon: SECURITY_OPTIMIZATION,
  text: `pages.consulting.benefit6`
}];
