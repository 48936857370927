import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  useParams, useNavigate, useLocation, Outlet
} from 'react-router-dom';

export default function LanguagePath() {
  const { i18n } = useTranslation();
  const { lang } = useParams();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const updateLanguage = async (l) => {
    await i18n.changeLanguage(l);
  };

  useEffect(() => {
    if (!lang) {
      navigate(`/${i18n.resolvedLanguage}${pathname}`, { replace: true });
    }
    if (lang && i18n.resolvedLanguage !== lang) {
      if (i18n.options.supportedLngs.includes(lang)) {
        updateLanguage(lang);
      } else {
        navigate(`/${i18n.resolvedLanguage}${pathname}`, { replace: true });
      }
    }
  }, [lang]);
  return <Outlet />;
}
