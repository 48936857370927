import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import Row from 'react-bootstrap/Row';

// styles
import Styles from './Achievements.styles';

// assets
import projects from '../../../../assets/achievements/projects.svg';
import collaborators from '../../../../assets/achievements/collaborators.svg';
import certifications from '../../../../assets/achievements/certifications.svg';
import experience from '../../../../assets/achievements/experience.svg';

// components
import CustomText from '../../../../components/atoms/CustomText';
import SimpleCardWithImage from '../../../../components/atoms/SimpleCardWithImage';

const MainContainer = styled.div`${Styles.container}`;
const CardsContainer = styled(Row)`${Styles.cardsContainer}`;

function Achievements() {
  const { t } = useTranslation();

  return (
    <MainContainer>
      <CustomText text={t(`pages.home.achievementsSection.title`)} type="title" />
      <CardsContainer className="m-0">
        <SimpleCardWithImage
          image={projects}
          title={t(`pages.home.achievementsSection.cards.projects.title`)}
          subtitle={t(`pages.home.achievementsSection.cards.projects.subtitle`)}
        />
        <SimpleCardWithImage
          image={collaborators}
          title={t(`pages.home.achievementsSection.cards.collaborators.title`)}
          subtitle={t(`pages.home.achievementsSection.cards.collaborators.subtitle`)}
        />
        <SimpleCardWithImage
          image={certifications}
          title={t(`pages.home.achievementsSection.cards.certifications.title`)}
          subtitle={t(`pages.home.achievementsSection.cards.certifications.subtitle`)}
        />
        <SimpleCardWithImage
          image={experience}
          title={t(`pages.home.achievementsSection.cards.experience.title`)}
          subtitle={t(`pages.home.achievementsSection.cards.experience.subtitle`)}
        />
      </CardsContainer>
    </MainContainer>
  );
}

export default Achievements;
