// Libs imports
import React, { useContext } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

// context
import { store } from '../../../../store';

// Styles
import Styles from './Infrastructure.styles';

// assets
import {
  AWS_IMAGES_PAGE_1,
  BENEFITS_OF_APP_MODERNIZATION,
  BENEFITS_OF_CLOUD_WORKLOAD,
  BENEFITS_OF_CONSULT_ARCH,
  BENEFITS_OF_DATA_LAKE,
  BENEFITS_OF_SOFT_OPTIMIZATION,
  BENEFITS_OF_WORKLOAD_MIGRATION,
  MIGRATION_STAGES,
  STAGES_OF_APPLICATION_MODERNIZATION,
  STAGES_OF_CONSULT_ARCH
} from '../../utils/constants';
import {
  CONST_INFRASTRUCTURE,
  CONSULTING_SOFTWARE_ARCHITECTURES,
  DATA_LAKERS,
  INFRASTRUCTURE_PAGE, MODERN_APPS, OPTIMIZATION_OF_CLOUD, OPTIMIZATION_OF_SOFTWARE_DELIVERIES,
  WORKLOAD_MIGRATION
} from '../../assets';

// components
import CategorySection from '../../components/CategorySection/CategorySection';
import CustomText from '../../../../components/atoms/CustomText';
import BenefitsContainer from '../../components/BenefitsContainer/BenefitsContainer';
import Contact from '../../../../components/molecules/Contact';
import AWSCertifications from '../../components/AWSCertifications/AWSCertifications';
import StageSection from '../../components/StageSection/StageSection';
import CategoryWithEllipsesIcon from '../../components/CategoryWithEllipsesIcon/CategoryWithEllipsesIcon';

const ServiceContainer = styled.div`${Styles.container}`;
const CategoryIcon = styled.img`${Styles.categoryIcon}`;
const ServiceConstellation = styled.img`${Styles.serviceConstellation}`;
const Element = styled.div``;

function Infrastructure() {
  const { t } = useTranslation();
  const { isMobile } = useContext(store);

  return (
    <ServiceContainer>
      <ServiceConstellation
        style={{
          marginLeft: isMobile ? `30px` : `12.9vw`,
          marginTop: isMobile ? `50px` : `8vw`
        }}
        src={CONST_INFRASTRUCTURE}
      />
      <CategorySection
        withEllipses
        leftElement={isMobile ? (
          <Element className="mb-5">
            <CustomText
              text={t(`pages.infrastructure.infrastructureTitle`)}
              type="title"
              className="mb-5"
              size="8vw"
            />
            <CategoryWithEllipsesIcon src={INFRASTRUCTURE_PAGE} width={isMobile ? `300px` : `30vw`} />
          </Element>
        ) : (
          <Element>
            <CustomText
              text={t(`pages.infrastructure.infrastructureTitle`)}
              type="title"
              className="mb-5"
            />
            <CustomText
              text={t(`pages.infrastructure.infrastructureDescription`)}
              type="caption"
            />
          </Element>
        )}
        rightElement={isMobile ? (
          <CustomText
            text={t(`pages.infrastructure.infrastructureDescription`)}
            type="caption"
          />
        ) : (<CategoryWithEllipsesIcon src={INFRASTRUCTURE_PAGE} width={isMobile ? `30vw` : `30vw`} />)}
      />
      <CategorySection
        leftElement={(
          <Element>
            <CustomText
              text={t(`pages.infrastructure.workloadMigration`)}
              type="subtitle-with-border"
              className="mb-5"
            />
            <CustomText
              text={t(`pages.infrastructure.workloadMigrationDescription`)}
              type="caption"
              className={isMobile ? `mb-3` : ``}
            />
          </Element>
        )}
        rightElement={(
          <CategoryIcon src={WORKLOAD_MIGRATION} width={isMobile ? `300px` : `600px`} />)}
      />
      <StageSection stages={MIGRATION_STAGES} generalDescription={t(`pages.infrastructure.stageDescription4`)} />
      <BenefitsContainer benefits={BENEFITS_OF_WORKLOAD_MIGRATION} />
      <CategorySection
        leftElement={(<CategoryIcon src={MODERN_APPS} width={isMobile ? `300px` : `600px`} />)}
        rightElement={(
          <Element className={isMobile ? `mt-3` : ``}>
            <CustomText
              text={t(`pages.infrastructure.modernizationTitle`)}
              type="subtitle-with-border"
              className="mb-5"
            />
            <CustomText
              text={t(`pages.infrastructure.modernizationDescription`)}
              type="caption"
            />
          </Element>
        )}
      />
      <StageSection stages={STAGES_OF_APPLICATION_MODERNIZATION} generalDescription={t(`pages.infrastructure.stageDescription4`)} />
      <BenefitsContainer benefits={BENEFITS_OF_APP_MODERNIZATION} />
      <CategorySection
        leftElement={(
          <Element>
            <CustomText
              text={t(`pages.infrastructure.cloudWorkloadTitle`)}
              type="subtitle-with-border"
              className="mb-5"
            />
            <CustomText
              text={t(`pages.infrastructure.cloudWorkloadDescription`)}
              type="caption"
              className={isMobile ? `mb-3` : ``}
            />
          </Element>
        )}
        rightElement={(<CategoryIcon src={OPTIMIZATION_OF_CLOUD} width={isMobile ? `300px` : `600px`} />)}
      />
      <BenefitsContainer benefits={BENEFITS_OF_CLOUD_WORKLOAD} />
      <CategorySection
        leftElement={(<CategoryIcon src={OPTIMIZATION_OF_SOFTWARE_DELIVERIES} width={isMobile ? `300px` : `600px`} />)}
        rightElement={(
          <Element className={isMobile ? `mt-3` : ``}>
            <CustomText
              text={t(`pages.infrastructure.softOptimizationTitle`)}
              type="subtitle-with-border"
              className="mb-5"
            />
            <CustomText
              text={t(`pages.infrastructure.softOptimizationDescription`)}
              type="caption"
            />
          </Element>
        )}
      />
      <BenefitsContainer benefits={BENEFITS_OF_SOFT_OPTIMIZATION} />
      <CategorySection
        leftElement={(
          <Element>
            <CustomText
              text={t(`pages.infrastructure.consultArchTitle`)}
              type="subtitle-with-border"
              className="mb-5"
            />
            <CustomText
              text={t(`pages.infrastructure.consultArchDescription`)}
              type="caption"
              className={isMobile ? `mb-3` : ``}
            />
          </Element>
        )}
        rightElement={(<CategoryIcon src={CONSULTING_SOFTWARE_ARCHITECTURES} width={isMobile ? `300px` : `600px`} />)}
      />
      <StageSection stages={STAGES_OF_CONSULT_ARCH} generalDescription={t(`pages.infrastructure.consultArchStageDescription5`)} />
      <BenefitsContainer benefits={BENEFITS_OF_CONSULT_ARCH} />
      <CategorySection
        leftElement={(<CategoryIcon src={DATA_LAKERS} width={isMobile ? `300px` : `600px`} />)}
        rightElement={(
          <Element className={isMobile ? `mt-3` : ``}>
            <CustomText
              text={t(`pages.infrastructure.dataLakeTitle`)}
              type="subtitle-with-border"
              className="mb-5"
              size={isMobile ? `8vw` : undefined}
            />
            <CustomText
              text={t(`pages.infrastructure.dataLakeDescription`)}
              type="caption"
            />
          </Element>
        )}
      />
      <BenefitsContainer benefits={BENEFITS_OF_DATA_LAKE} />
      <AWSCertifications certifications={AWS_IMAGES_PAGE_1} />
      <Contact />
    </ServiceContainer>
  );
}

export default Infrastructure;
