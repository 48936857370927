export default {
  container: {
    alignItems: `center`,
    minHeight: `17.2vw`,
    marginRight: 0,
    backgroundColor: `#F7F7F7`,
    zIndex: 9
  },
  image: {
    height: `max(15vw, 250px)`,
    alignSelf: `center`,
    backgroundColor: `#FFFFFF`,
    borderRadius: 200
  }
};
