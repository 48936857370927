export default {
  container: {
    display: `flex`,
    position: `relative`,
    flexDirection: `column`,
    alignItems: `center`,
    marginTop: `80px`,
    paddingTop: `max(6.61vw, 88px)`,
    paddingBottom: `max(6.61vw, 88px)`,
    paddingLeft: `max(14vw, 30px)`,
    paddingRight: `max(11vw, 30px)`
  },
  title: {
    color: `#D8D8D8`
  },
  topEllipsis: {
    height: `18rem`,
    position: `absolute`,
    top: `-12rem`,
    opacity: 0.2,
    filter: `blur(50px)`,
    '-webkit-backdrop-filter': `blur(50px);`
  },
  leftEllipsis: {
    height: `3.25rem`,
    position: `absolute`,
    bottom: `30%`,
    left: `3.95vw`,
    opacity: 0.66,
    filter: `blur(30px)`,
    '-webkit-backdrop-filter': `blur(30px);`
  }
};
