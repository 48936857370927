export default {
  container: {
    position: `relative`,
    display: `flex`,
    flexDirection: `column`,
    marginTop: `80px`,
    width: `100%`,
    maxWidth: `100%`,
    overflow: `hidden`
  },
  categoryIcon: {
    display: `flex`,
    margin: `auto`
  },
  serviceConstellation: {
    width: `253px`,
    height: `65px`
  }
};
