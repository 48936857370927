import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import Row from 'react-bootstrap/Row';
import { useTranslation } from 'react-i18next';

// context
import { store } from '../../../../../store';

// styles
import Styles from './ContactButtons.styles';

// assets
import phoneActive from './assets/phoneActive.svg';
import phoneInactive from './assets/phoneInactive.svg';
import mailActive from './assets/mailActive.svg';
import mailInactive from './assets/mailInactive.svg';

// components
import CustomText from '../../../../atoms/CustomText';

const ButtonsContainer = styled(Row)`${Styles.container}`;
const StyledButton = styled.a`${Styles.button}`;
const Icon = styled.img`${Styles.icon}`;

function Button({
  children,
  action,
  activeIcon,
  inactiveIcon
}) {
  const [hover, setHover] = useState(false);
  const toggleHover = () => { setHover(!hover); };

  const { isMobile } = useContext(store);

  return (
    <StyledButton
      onMouseEnter={toggleHover}
      onMouseLeave={toggleHover}
      onClick={action}
    >
      <Icon src={(hover || isMobile) ? activeIcon : inactiveIcon} />
      {children}
    </StyledButton>
  );
}

function ContactButtons() {
  const { t } = useTranslation();

  return (
    <ButtonsContainer className="m-0 justify-content-center pt-4 pt-md-0 pb-4 pb-md-0">
      <Button
        className="col-12 col-md-5"
        activeIcon={phoneActive}
        inactiveIcon={phoneInactive}
        action={(e) => {
          e.preventDefault();
          window.open(`tel:${t(`components.contactButtons.contactPhone`)}`, `_blank`);
        }}
      >
        <CustomText text={t(`components.contactButtons.phoneButtonLabel`)} type="caption-regular" />
      </Button>
      <Button
        className="col-12 col-md-5"
        activeIcon={mailActive}
        inactiveIcon={mailInactive}
        action={(e) => {
          e.preventDefault();
          window.open(`mailto:${t(`components.contactButtons.contactMail`)}`, `_blank`);
        }}
      >
        <CustomText text={t(`components.contactButtons.mailButtonLabel`)} type="caption-regular" />
      </Button>
    </ButtonsContainer>
  );
}

export default ContactButtons;
