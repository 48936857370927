export default {
  container: {
    paddingTop: `3.7vw`
  },
  titleContainer: {
    paddingLeft: `4.25vw`,
    maxWidth: `70%`,
    borderLeftColor: `#FFD525`,
    borderLeftWidth: `max(1vw, 10px)`,
    borderLeftStyle: `solid`,
    marginLeft: `0.1vw`,
    marginBottom: `1vw`,
    marginTop: `1vw`
  }
};
