export default {
  container: {
    width: `410px`,
    height: `auto`,
    background: `#FFFFFF`,
    boxShadow: `0px 3px 99px #58585829`,
    borderRadius: `24px`,
    opacity: `1`,
    marginRight: `5rem`,
    marginBottom: `2rem`,
    '@media (max-width: 992px)': {
      marginRight: `0rem`
    }
  },
  yellowText: {
    color: `#FFD524`,
    fontSize: `2.5rem`,
    fontFamily: `geologica-bold`
  },
  img: {
    display: `flex`,
    maxWidth: `max(50%, 212px)`,
    height: `max(3vw)`,
    objectFit: `contain`,
    alignSelf: `flex-start`,
    '@media (max-width: 992px)': {
      maxWidth: `max(80%, 212px)`,
      height: `max(8vw)`
    }
  },
  containerText: {
    display: `flex`,
    alignItems: `center`,
    marginBottom: 10
  },
  yellowCircle: {
    width: `.7rem`,
    height: `.7rem`,
    borderRadius: `50%`,
    marginRight: `16px`,
    backgroundColor: `#FFD524`
  },
  sectorContainer: {
    display: `flex`,
    alignItems: `center`,
    marginBottom: 10,
    width: `185px`,
    height: `47px`,
    background: `#F7F7F7`,
    borderRadius: `0px 24px 24px 0px`,
    opacity: 1,
    padding: `0 15px`,
    justifyContent: `space-evenly`
  },
  cardContainer: {
    padding: `2rem`
  },
  imageContainer: {
    backgroundColor: `#F8F8F8`,
    borderRadius: `18px`,
    height: `max(8vw)`,
    justifyContent: `center`,
    display: `grid`,
    padding: `5px`,
    alignContent: `center`,
    '@media (max-width: 992px)': {
      height: `max(16vw)`
    }
  }
};
