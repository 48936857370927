export default {
  container: {
    alignItems: `center`,
    minHeight: `13.75vw`,
    zIndex: 9,
    backgroundColor: `#FFFFFF`
  },
  button: {
    display: `flex`,
    alignItems: `center`,
    justifyContent: `center`,
    cursor: `pointer`,
    width: `100%`,
    minHeight: `104px`,
    margin: `1rem`,
    maxWidth: `max(29vw, 345px)`,
    borderStyle: `solid`,
    borderWidth: `1px`,
    borderColor: `#242421`,
    borderRadius: `20px`,
    boxShadow: `0px 3px 99px #00000015`
  },
  icon: {
    width: `40px`,
    marginRight: `20px`
  }
};
