import React from 'react';

function CustomText({
  text,
  type,
  size,
  textAlign,
  className,
  marginTop,
  marginBottom,
  lineHeight,
  letterSpacing,
  opacity,
  color
}) {
  let Element;
  const textStyle = {
    textAlign,
    fontSize: size,
    whiteSpace: `pre-line`,
    marginTop,
    marginBottom,
    color
  };

  switch (type) {
  case `big-title`:
    Element = `h1`;
    Object.assign(textStyle, {
      fontFamily: `red-hat-black`,
      fontSize: size || `max(5.72vw, 50px)`,
      lineHeight: `max(7.6vw, 66px)`,
      letterSpacing: `0px`,
      color: `#242421`,
      opacity: 1,
      display: `block`
    });
    break;
  case `light-title`:
    Element = `h1`;
    Object.assign(textStyle, {
      fontFamily: `red-hat-regular`,
      fontSize: size || `max(3.64vw, 50px)`,
      lineHeight: `max(4.79vw, 60px)`,
      letterSpacing: `0px`,
      color: `#242421`,
      opacity: 1,
      display: `block`
    });
    break;
  case `title`:
    Element = `h1`;
    Object.assign(textStyle, {
      fontFamily: `red-hat-black`,
      fontSize: size || `max(3.64vw, 50px)`,
      lineHeight: `max(4.79vw, 60px)`,
      letterSpacing: letterSpacing || `0px`,
      color: `#242421`,
      opacity: opacity || 1,
      display: `block`
    });
    break;
  case `subtitle`:
    Element = `h2`;
    Object.assign(textStyle, {
      fontFamily: `red-hat-black`,
      fontSize: size || `max(2.5vw, 40px)`,
      lineHeight: lineHeight || `max(3.33vw, 70px)`,
      color: `#242421`
    });
    break;
  case `subtitle-with-border`:
    Element = `h2`;
    Object.assign(textStyle, {
      fontFamily: `red-hat-black`,
      fontSize: size || `max(2.5vw, 40px)`,
      lineHeight: `max(3.33vw, 53px)`,
      color: `#242421`,
      borderLeftColor: `#FFD525`,
      borderLeftStyle: `outset`,
      borderLeftWidth: `20px`,
      paddingLeft: `10px`
    });
    break;
  case `light-subtitle`:
    Element = `h2`;
    Object.assign(textStyle, {
      fontFamily: `geologica-extra-light`,
      fontSize: size || `max(2.5vw, 40px)`,
      lineHeight: lineHeight || `max(3.33vw, 70px)`,
      letterSpacing: letterSpacing || `2.8px`,
      color: `#242421`,
      display: `block`
    });
    break;
  case `menu-option`:
    Element = `small`;
    Object.assign(textStyle, {
      fontFamily: `red-hat-bold`,
      fontSize: size || `max(0.73vw, 14px)`,
      lineHeight: `max(0.83vw, 16px)`,
      color: `#202020`
    });
    break;
  case `footer-section-title`:
    Element = `small`;
    Object.assign(textStyle, {
      fontFamily: `geologica-bold`,
      fontSize: `max(0.94vw, 18px)`,
      lineHeight: `max(1.14vw, 22px)`,
      color: `#626262`,
      marginTop: `10px`,
      marginBottom: `10px`
    });
    break;
  case `footer-option`:
    Element = `small`;
    Object.assign(textStyle, {
      fontFamily: `geologica-extra-light`,
      fontSize: `max(0.83vw, 16px)`,
      lineHeight: `max(1.14vw, 22px)`,
      color: `#FFFFFF`,
      textOverflow: `ellipsis`,
      overflow: `hidden`
    });
    break;
  case `card-small-title`:
    Element = `small`;
    Object.assign(textStyle, {
      fontFamily: `red-hat-black`,
      fontSize: `max(2vw, 32px)`,
      lineHeight: `max(2.75vw, 43px)`,
      display: `block`,
      color: `#242421`
    });
    break;
  case `card-small-description`:
    Element = `small`;
    Object.assign(textStyle, {
      fontFamily: `geologica-extra-light`,
      fontSize: size || `max(1vw, 20px)`,
      lineHeight: `max(1.66vw, 32px)`,
      letterSpacing: letterSpacing || `normal`,
      display: `block`,
      color: color || `#242421`
    });
    break;
  case `caption`:
    Element = `p`;
    Object.assign(textStyle, {
      fontFamily: `geologica-extra-light`,
      fontSize: size || `max(1vw, 20px)`,
      lineHeight: `40px`,
      letterSpacing: letterSpacing || `normal`,
      color: `#202020`,
      margin: 0
    });
    break;
  case `caption-regular`:
    Element = `p`;
    Object.assign(textStyle, {
      fontFamily: `geologica-regular`,
      fontSize: size || `20px`,
      lineHeight: `40px`,
      color: `#202020`,
      margin: 0
    });
    break;
  case `caption-more-letter-spacing`:
    Element = `p`;
    Object.assign(textStyle, {
      fontFamily: `geologica-regular`,
      fontSize: size || `20px`,
      lineHeight: `40px`,
      color: `#202020`,
      letterSpacing: `2px`,
      margin: 0
    });
    break;
  case `caption-light-letter-spacing`:
    Element = `p`;
    Object.assign(textStyle, {
      fontFamily: `geologica-extra-light`,
      fontSize: size || `20px`,
      lineHeight: `25px`,
      color: `#242421`,
      letterSpacing: `2px`,
      margin: 0
    });
    break;
  case `caption-bold`:
    Element = `p`;
    Object.assign(textStyle, {
      fontFamily: `geologica-bold`,
      fontWeight: 200,
      fontSize: size || `20px`,
      lineHeight: `40px`,
      color: `#202020`,
      margin: 0,
      textOverflow: `ellipsis`,
      overflow: `hidden`
    });
    break;
  case `caption-bold-grey`:
    Element = `p`;
    Object.assign(textStyle, {
      fontFamily: `geologica-bold`,
      fontWeight: 200,
      fontSize: size || `45px`,
      lineHeight: `35px`,
      color: `#F7F7F7`
    });
    break;
  default:
    Element = `p`;
    Object.assign(textStyle, {
      fontFamily: `geologica-extra-light`,
      fontWeight: 200,
      fontSize: size || `20px`,
      lineHeight: `40px`,
      color: `#202020`,
      margin: 0
    });
    break;
  }

  return (
    <Element style={textStyle} className={className} dangerouslySetInnerHTML={{ __html: text }} />
  );
}

export default CustomText;
