import React, { useContext } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

// context
import { store } from '../../../../store';

// assets
import CignuzLogo from '../../../../assets/logoCignuz.svg';

// styles
import Styles from './Intro.styles';

// components
import CustomText from '../../../../components/atoms/CustomText';

const Container = styled.div`${Styles.container}`;
const TitleContainer = styled(Row)`${Styles.titleContainer}`;
const Cignuz = styled.img`${Styles.cignuzLogo}`;

function Intro() {
  const { t } = useTranslation();

  const { isMobile } = useContext(store);

  return (
    <Container
      style={{
        paddingLeft: isMobile ? `30px` : `19vw`,
        paddingRight: isMobile ? `30px` : `15vw`
      }}
    >
      <TitleContainer>
        <Col xs="auto" className="p-0 pr-3">
          <CustomText text={t(`pages.aboutUs.cignuz.cignuzSubtitle`)} type="subtitle" />
        </Col>
        <Col xs="auto" className="p-0">
          <Row className="align-items-center m-0">
            <Cignuz src={CignuzLogo} />
            <CustomText text={t(`pages.aboutUs.cignuz.cignuzSubtitleClosing`)} type="subtitle" />
          </Row>
        </Col>
      </TitleContainer>
      <CustomText text={t(`pages.aboutUs.cignuz.paragraph1`)} type="caption" className="mb-3" />
      <CustomText text={t(`pages.aboutUs.cignuz.paragraph2`)} type="caption" className="mb-3" />
      <CustomText text={t(`pages.aboutUs.cignuz.paragraph3`)} type="caption" className="mb-3" />
      <CustomText text={t(`pages.aboutUs.cignuz.paragraph4`)} type="caption" className="mb-5" />

      <Row className="m-0 justify-content-center mb-5">
        <CustomText text={t(`pages.aboutUs.cignuz.weAreCuzOfYou`)} type="light-title" textAlign="center" className="mr-md-3" />
        <CustomText text={t(`pages.aboutUs.cignuz.weAreCuzOfYou2`)} type="title" textAlign="center" />
      </Row>
    </Container>
  );
}

export default Intro;
