import React from 'react';
import styled from 'styled-components';
import Container from 'react-bootstrap/Container';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// assets
import Styles from './ServicesSubmenu.styles';

// components
import CustomText from '../../../atoms/CustomText';

const MenuContainer = styled(Container)`${Styles.menuContainer}`;
const MenuOptionWrapper = styled.a`${Styles.menuOptionWrapper}`;

function ServicesSubmenu({ onClose, innerRef }) {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const lang = i18n.resolvedLanguage;

  const goToPage = (link) => {
    navigate(`/${lang}/${t(`routes.services`)}/${link}`);
    onClose();
  };

  return (
    <MenuContainer ref={innerRef} className="pl-5">
      <MenuOptionWrapper onClick={() => { goToPage(t(`routes.subServices.softwareDev`)); }}>
        <CustomText text={t(`routes.subServicesTitles.softwareDev`)} type="menu-option" />
      </MenuOptionWrapper>
      <MenuOptionWrapper onClick={() => { goToPage(t(`routes.subServices.infrastructure`)); }}>
        <CustomText text={t(`routes.subServicesTitles.infrastructure`)} type="menu-option" />
      </MenuOptionWrapper>
      <MenuOptionWrapper onClick={() => { goToPage(t(`routes.subServices.telecommunication`)); }}>
        <CustomText text={t(`routes.subServicesTitles.telecommunication`)} type="menu-option" />
      </MenuOptionWrapper>
      <MenuOptionWrapper onClick={() => { goToPage(t(`routes.subServices.security`)); }}>
        <CustomText text={t(`routes.subServicesTitles.security`)} type="menu-option" />
      </MenuOptionWrapper>
      <MenuOptionWrapper onClick={() => { goToPage(t(`routes.subServices.design`)); }}>
        <CustomText text={t(`routes.subServicesTitles.design`)} type="menu-option" />
      </MenuOptionWrapper>
      <MenuOptionWrapper onClick={() => { goToPage(t(`routes.subServices.consultancy`)); }}>
        <CustomText text={t(`routes.subServicesTitles.consultancy`)} type="menu-option" />
      </MenuOptionWrapper>
    </MenuContainer>
  );
}

export default ServicesSubmenu;
