export default {
  menuContainer: {
    position: `fixed`,
    top: 0,
    minHeight: `80px`,
    width: `100%`,
    maxWidth: `100%`,
    zIndex: 30,
    paddingTop: `46px`,
    paddingBottom: `15px`,
    backgroundColor: `rgba(247, 247, 247, 0.5)`,
    backdropFilter: `blur(17px)`,
    '-webkit-backdrop-filter': `blur(17px);`
  },
  menuOptionWrapper: {
    display: `flex`,
    flexDirection: `row`,
    alignItems: `center`,
    height: `1.25rem`,
    cursor: `pointer`,
    marginBottom: `1rem`
  }
};
