import React, { useContext } from 'react';
import styled from 'styled-components';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { useTranslation } from 'react-i18next';

// context
import { store } from '../../../../store';

// styles
import Styles from './MissionAndVision.styles';

// components
const Container = styled(Row)`${Styles.container}`;
const Section = styled(Col)`${Styles.section}`;
const Subtitle = styled.small`${Styles.subtitle}`;
const Title = styled.small`${Styles.title}`;
const Description = styled.small`${Styles.description}`;
const Bold = styled.small`${Styles.bold}`;

function MissionAndVision() {
  const { isMobile } = useContext(store);
  const { t } = useTranslation();

  return (
    <Container>
      <Section xs={12} lg={5}>
        <Subtitle>{t(`pages.aboutUs.mission.subtitle`)}</Subtitle>
        <Title>{t(`pages.aboutUs.mission.title`)}</Title>
        <Description
          style={{
            width: isMobile ? `100%` : `80%`
          }}
        >
          {t(`pages.aboutUs.mission.description`)}
          <Bold>{t(`pages.aboutUs.mission.boldWords`)}</Bold>
        </Description>
      </Section>
      <Section
        xs={12}
        lg={5}
        style={{
          borderLeftWidth: isMobile ? 0 : `1px`,
          borderLeftColor: `#242421`,
          borderLeftStyle: `solid`
        }}
      >
        <Subtitle>{t(`pages.aboutUs.vision.subtitle`)}</Subtitle>
        <Title>{t(`pages.aboutUs.vision.title`)}</Title>
        <Description
          style={{
            width: isMobile ? `100%` : `80%`
          }}
        >
          {t(`pages.aboutUs.vision.description`)}
          <Bold>{t(`pages.aboutUs.vision.boldWords`)}</Bold>
        </Description>
      </Section>
    </Container>
  );
}

export default MissionAndVision;
