import React from 'react';
import styled from 'styled-components';
import Styles from './CircularCheckbox.Styles';

const CheckboxContainer = styled.div`${Styles.CheckboxContainer}`;
const StyledCheckbox = styled.button`${Styles.StyledCheckbox}`;
const CheckboxButton = styled.div`${Styles.CheckboxButton}`;

function CircularCheckbox({ label, isSelected, onCheckboxChange }) {
  const handleCheckboxChange = () => {
    onCheckboxChange(!isSelected);
  };

  return (
    <CheckboxContainer>
      <StyledCheckbox checked={isSelected} onClick={handleCheckboxChange} />
      <CheckboxButton>
        {label}
      </CheckboxButton>
    </CheckboxContainer>
  );
}

export default CircularCheckbox;
