export default {
  container: {
    position: `relative`,
    display: `flex`,
    flexDirection: `column`,
    marginTop: `80px`,
    width: `100%`,
    overflow: `hidden`,
    aspectRatio: `1/1`,
    margin: `0 auto`,
    alignSelf: `center`
  },
  categoryIcon: {
    position: `relative`,
    zIndex: 1,
    borderRadius: `50%`,
    overflow: `hidden`,
    width: `100%`,
    height: `100%`
  },
  circles: {
    position: `absolute`
  },
  circle1: {
    content: `""`,
    position: `absolute`,
    width: `30%`,
    paddingTop: `30%`,
    border: `1px solid #707070`,
    borderRadius: `50%`,
    opacity: 1,
    zIndex: -1
  },
  circle2: {
    content: `""`,
    position: `absolute`,
    bottom: `-1%`,
    right: `-5%`,
    width: `70%`,
    paddingTop: `70%`,
    border: `1px solid #707070`,
    borderRadius: `50%`,
    opacity: 1,
    zIndex: -3
  }
};
