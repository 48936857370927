import React, { useContext } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

// context
import { store } from '../../../../store';

// styles
import Styles from './Intro.styles';

// components
import CustomText from '../../../../components/atoms/CustomText';

const Container = styled.div`${Styles.container}`;
const TitleContainer = styled.div`${Styles.titleContainer}`;

function Intro() {
  const { isMobile } = useContext(store);
  const { t } = useTranslation();

  return (
    <Container
      style={{
        paddingLeft: isMobile ? `30px` : `19vw`,
        paddingRight: isMobile ? `30px` : `15vw`
      }}
    >
      <TitleContainer>
        <CustomText text={t(`pages.policies.intro.title`)} type="subtitle" />
      </TitleContainer>
      <CustomText text={t(`pages.policies.intro.paragraph1`)} type="caption" className="mb-3" />
      <CustomText text={t(`pages.policies.intro.paragraph2`)} type="caption" className="mb-3" />
      <CustomText text={t(`pages.policies.intro.paragraph3`)} type="caption" className="mb-5" />
    </Container>
  );
}

export default Intro;
