import React, { useContext } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

// context
import { store } from '../../../../store';

// styles
import Styles from './ConductCode.styles';

// assets
import conductCode from '../../../../assets/files/conductCode.pdf';

// components
import CustomText from '../../../../components/atoms/CustomText';
import BaseButton from '../../../../components/atoms/BaseButton';

const Container = styled.div`${Styles.container}`;
const InnerContainer = styled.div`${Styles.innerContainer}`;

function ConductCode() {
  const { isMobile } = useContext(store);
  const { t } = useTranslation();

  return (
    <Container
      style={{
        paddingLeft: isMobile ? `30px` : `19vw`,
        paddingRight: isMobile ? `30px` : `15vw`
      }}
    >
      <InnerContainer
        style={{
          paddingLeft: isMobile ? `35px` : `7.55vw`,
          paddingRight: isMobile ? `35px` : `7.55vw`,
          paddingTop: isMobile ? `43px` : `4.63vw`,
          paddingBottom: isMobile ? `28px` : `4.94vw`
        }}
      >
        <CustomText text={t(`pages.policies.conductCode.title`)} type="subtitle" lineHeight="53px" />
        <CustomText text={t(`pages.policies.conductCode.description`)} type="caption" />
        <BaseButton
          type="file"
          label={t(`pages.policies.conductCode.buttonText`)}
          onClick={(e) => {
            e.preventDefault();
            window.open(`${conductCode}`);
          }}
          maxWidth="100%"
          iconStyles={{
            width: `55px`,
            marginLeft: `10px`,
            marginRight: `5px`
          }}
        />
      </InnerContainer>
    </Container>
  );
}

export default ConductCode;
