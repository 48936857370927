/* eslint-disable no-console */
// Libs imports
import React, { useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from "react-i18next";

// context
import { Row } from 'react-bootstrap';

// Styles
import Styles from './Projects.styles';

// Assets
import YellowCircle from '../../assets/yellowCircle.png';

// Components
import CustomText from '../../components/atoms/CustomText';
import BaseButton from '../../components/atoms/BaseButton';
import CircularCheckbox from '../../components/atoms/CircularCheckbox';
import Contact from '../../components/molecules/Contact';
import CardComponent from '../../components/atoms/CardComponent';

const Container = styled.div`${Styles.container}`;
const TopEllipsis = styled.img`${Styles.topEllipsis}`;
const LeftEllipsis = styled.img`${Styles.leftEllipsis}`;
const ButtonsContainer = styled(Row)`${Styles.buttonsContainer}`;
const CheckboxContainer = styled(Row)`${Styles.checkboxContainer}`;
const CardsContainer = styled.div`${Styles.cardsContainer}`;

function Projects() {
  const { t } = useTranslation();

  const [selectedCategories] = useState([]);
  const [selectedSector, setSelectedSector] = useState(null); // Puede ser 'public' o 'private'

  const [checkboxOptions, setCheckboxOptions] = useState([
    {
      id: 1,
      key: `allProjects`,
      label: t(`pages.projects.allProjects`),
      isSelected: true
    },
    {
      id: 2,
      key: `softwareDevelopment`,
      label: t(`pages.projects.softwareDevelopment`),
      isSelected: false
    },
    {
      id: 3,
      key: `AWSInfrastructure`,
      label: t(`pages.projects.AWSInfrastructure`),
      isSelected: false
    },
    {
      id: 4,
      key: `callCenter`,
      label: t(`pages.projects.callCenter`),
      isSelected: false
    },
    {
      id: 5,
      key: `softwareSecurity`,
      label: t(`pages.projects.softwareSecurity`),
      isSelected: false
    },
    {
      id: 6,
      key: `UxUI`,
      label: t(`pages.projects.UxUI`),
      isSelected: false
    },
    {
      id: 7,
      key: `specializedConsulting`,
      label: t(`pages.projects.specializedConsulting`),
      isSelected: false
    }
  ]);

  const projectData = [
    {
      year: 2023,
      name: `Nombre del Proyecto 1`,
      company: `Empresa 1`,
      description: `Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.`,
      category1: `Software Development`,
      sector: `Sector público`
    },
    {
      year: 2023,
      name: `Nombre del Proyecto 2`,
      company: `Empresa 1`,
      description: `Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.`,
      category1: `Software Development`,
      sector: `Sector público`
    },
    {
      year: 2023,
      name: `Nombre del Proyecto 3`,
      company: `Empresa 1`,
      description: `Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.`,
      category1: `Software Development`,
      sector: `Sector público`
    },
    {
      year: 2023,
      name: `Nombre del Proyecto 4`,
      company: `Empresa 1`,
      description: `Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.`,
      category1: `Software Development`,
      sector: `Sector público`
    }
  ];

  const handleCheckboxChange = (id) => {
    setCheckboxOptions((prevOptions) => prevOptions.map((option) => (option.id === id ? { ...option, isSelected: !option.isSelected } : option)));
  };

  const handleSectorChange = (sector) => {
    setSelectedSector(sector);
  };

  const filteredProjects = projectData.filter((project) => {
    const categoryMatch = selectedCategories.length === 0 || selectedCategories.includes(project.category1);
    console.log(categoryMatch, selectedCategories, `categoryMatch`);
    const sectorMatch = selectedSector === null || (selectedSector === `public` && project.sector === `Sector público`) || (selectedSector === `private` && project.sector !== `Sector público`);
    return categoryMatch && sectorMatch;
  });

  return (
    <Container>
      <TopEllipsis src={YellowCircle} />
      <CustomText text={t(`pages.projects.title`)} type="big-title" textAlign="center" />
      <ButtonsContainer>
        <BaseButton
          type="file"
          label={t(`pages.projects.publicSector`)}
          styles={{
            width: `100%`,
            maxWidth: `max(35vw, 345px)`
          }}
          onClick={() => handleSectorChange(`public`)}
        />
        <BaseButton
          type="file"
          label={t(`pages.projects.privateSector`)}
          styles={{
            width: `100%`,
            maxWidth: `max(35vw, 345px)`
          }}
          onClick={() => handleSectorChange(`private`)}
        />
      </ButtonsContainer>
      <CheckboxContainer>
        {checkboxOptions.map((option) => (
          <CircularCheckbox
            key={option.id}
            label={option.label}
            isSelected={option.isSelected}
            onCheckboxChange={() => handleCheckboxChange(option.id)}
          />
        ))}
      </CheckboxContainer>
      <CardsContainer>
        {filteredProjects.map((project) => (
          <CardComponent key={project.name} project={project} />
        ))}
      </CardsContainer>
      <LeftEllipsis src={YellowCircle} className="d-none d-lg-flex" />
      <Contact />
    </Container>
  );
}

export default Projects;
