export default {
  container: {
    position: `relative`,
    flexDirection: `column`,
    alignItems: `center`,
    marginTop: `80px`,
    paddingTop: `max(6.61vw, 88px)`,
    paddingBottom: `max(6.61vw, 88px)`,
    paddingLeft: `max(14vw, 30px)`,
    paddingRight: `max(11vw, 30px)`
  },
  title: {
    color: `#D8D8D8`
  },
  topEllipsis: {
    height: `18rem`,
    position: `absolute`,
    top: `-12rem`,
    opacity: 0.2,
    filter: `blur(50px)`,
    '-webkit-backdrop-filter': `blur(50px);`
  },
  leftEllipsis: {
    height: `3.25rem`,
    position: `absolute`,
    bottom: `30%`,
    left: `3.95vw`,
    opacity: 0.66,
    filter: `blur(30px)`,
    '-webkit-backdrop-filter': `blur(30px);`
  },
  buttonsContainer: {
    alignItems: `center`,
    justifyContent: `space-evenly`,
    borderBottom: `1px solid #D8D8D8`,
    paddingBottom: `1rem`,
    marginTop: `4rem`
  },
  checkboxContainer: {
    marginTop: 41,
    alignItems: `center`,
    flexWrap: `nowrap`,
    '@media (max-width: 992px)': {
      flexWrap: `wrap`,
      alignItems: `center`
    }
  },
  card: {
    width: `410px`,
    height: `721px`,
    background: `#FFFFFF`,
    boxShadow: `0px 3px 99px #58585829`,
    borderRadius: `24px`,
    opacity: `1`
  },
  cardsContainer: {
    marginTop: `3rem`,
    marginBottom: `4rem`,
    display: `flex`,
    flexWrap: `wrap`,
    justifyContent: `center`
  }
};
