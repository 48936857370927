import React, {
  useState,
  useContext,
  useRef,
  useEffect
} from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// context
import { store } from '../../store';

// assets
import logoCignuz from '../../assets/logoCignuz.svg';
import styles from './Header.styles';
import MenuToggleIcon from './assets/MenuToggleIcon.svg';
import LanguageInactive from './assets/languageInactive.svg';
import LanguageActive from './assets/languageActive.svg';
import servicesActive from '../../assets/icons/servicesActive.svg';
import servicesInactive from '../../assets/icons/servicesInactive.svg';
import aboutUsActive from '../../assets/icons/aboutUsActive.svg';
import aboutUsInactive from '../../assets/icons/aboutUsInactive.svg';
import policiesActive from '../../assets/icons/policiesActive.svg';
import policiesInactive from '../../assets/icons/policiesInactive.svg';
import contactActive from '../../assets/icons/contactActive.svg';
import contactInactive from '../../assets/icons/contactInactive.svg';

// components
import FloatingMenu from './components/FloatingMenu';
import CustomText from '../atoms/CustomText';
import ServicesSubmenu from './components/ServicesSubmenu';

const HeaderContainer = styled.header`${styles.headerContainer}`;

const LogoWrapper = styled.a`${styles.logoWrapper}`;
const Logo = styled.img`${styles.logo}`;

const OptionsContainer = styled.div`${styles.optionsContainer}`;
const OptionWrapper = styled.a`${styles.optionWrapper}`;
const OptionIcon = styled.img`${styles.optionIcon}`;
const OptionLabel = styled(CustomText)`${styles.optionLabel}`;

const ResponsiveOptionsContainer = styled.div`${styles.responsiveOptsContainer}`;
const MenuToggleContainer = styled.a`${styles.menuToggleContainer}`;
const MenuToggle = styled.img`${styles.menuToggle}`;

function Header() {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const { isMobile } = useContext(store);

  const lang = i18n.resolvedLanguage;

  const [hoverServices, setHoverServices] = useState(false);
  const [hoverAboutUs, setHoverAboutUs] = useState(false);
  const [hoverPolicies, setHoverPolicies] = useState(false);
  const [hoverContact, setHoverContact] = useState(false);
  const [hoverLanguage, setHoverLanguage] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [showServicesSubmenu, setShowServicesSubmenu] = useState(false);
  const [langOpts, setLangOpts] = useState(false);

  const floatingMenuRef = useRef(null);
  const servicesSubmenuRef = useRef(null);

  const toggleHover = (updateFn, value) => {
    updateFn(value);
  };

  const goToPage = (page) => {
    navigate(page);
  };

  const toggleShowMenu = () => {
    setLangOpts(false);
    setShowMenu(!showMenu);
  };

  const openLangMenu = () => {
    setLangOpts(true);
    setShowMenu(!showMenu);
  };

  const onClose = () => {
    setShowMenu(false);
    setLangOpts(false);
  };

  const openSubmenu = () => {
    setShowServicesSubmenu(!showServicesSubmenu);
  };

  const onCloseSubmenu = () => {
    setShowServicesSubmenu(!showServicesSubmenu);
  };

  const changeLanguage = () => {
    i18n.changeLanguage(i18n.resolvedLanguage === `es` ? `en` : `es`)
      .then(() => {
        navigate(`/`);
        setShowMenu(false);
        setLangOpts(false);
      });
  };

  const handleClickOutside = (event) => {
    if (floatingMenuRef.current && !floatingMenuRef.current.contains(event.target)) {
      setShowMenu(false);
      setLangOpts(false);
    }
    if (servicesSubmenuRef.current && !servicesSubmenuRef.current.contains(event.target)) {
      setShowServicesSubmenu(false);
    }
  };

  useEffect(() => {
    document.addEventListener(`mousedown`, handleClickOutside);
    return () => {
      document.removeEventListener(`mousedown`, handleClickOutside);
    };
  }, [floatingMenuRef, servicesSubmenuRef]);

  return (
    <>
      <HeaderContainer>
        <LogoWrapper
          className="ml-md-5 ml-3"
          onClick={() => {
            navigate(`/`);
            setShowMenu(false);
          }}
        >
          <Logo className="ml-lg-5" src={logoCignuz} />
        </LogoWrapper>
        <OptionsContainer>
          <OptionWrapper
            onMouseEnter={() => toggleHover(setHoverServices, !hoverServices)}
            onMouseLeave={() => toggleHover(setHoverServices, !hoverServices)}
            onClick={() => { openSubmenu(); }}
            className="mr-5 d-none d-lg-flex"
          >
            <OptionIcon src={hoverServices ? servicesActive : servicesInactive} />
            <OptionLabel
              className="mr-5"
              text={t(`sidebar.services`)}
              type="menu-option"
            />
          </OptionWrapper>
          <OptionWrapper
            onMouseEnter={() => toggleHover(setHoverAboutUs, !hoverAboutUs)}
            onMouseLeave={() => toggleHover(setHoverAboutUs, !hoverAboutUs)}
            onClick={() => { goToPage(`/${lang}/${t(`routes.aboutUs`)}`); }}
            className="mr-5 d-none d-lg-flex"
          >
            <OptionIcon src={hoverAboutUs ? aboutUsActive : aboutUsInactive} />
            <OptionLabel
              className="mr-5"
              text={t(`sidebar.aboutUs`)}
              type="menu-option"
            />
          </OptionWrapper>
          <OptionWrapper
            onMouseEnter={() => toggleHover(setHoverPolicies, !hoverPolicies)}
            onMouseLeave={() => toggleHover(setHoverPolicies, !hoverPolicies)}
            onClick={() => { goToPage(`/${lang}/${t(`routes.policies`)}`); }}
            className="mr-5 d-none d-lg-flex"
          >
            <OptionIcon src={hoverPolicies ? policiesActive : policiesInactive} />
            <OptionLabel
              className="mr-5"
              text={t(`sidebar.policies`)}
              type="menu-option"
            />
          </OptionWrapper>
          <OptionWrapper
            onMouseEnter={() => toggleHover(setHoverContact, !hoverContact)}
            onMouseLeave={() => toggleHover(setHoverContact, !hoverContact)}
            onClick={() => { goToPage(`/${lang}/${t(`routes.contact`)}`); }}
            className="mr-5 d-none d-lg-flex"
          >
            <OptionIcon src={hoverContact ? contactActive : contactInactive} />
            <OptionLabel
              className="mr-5"
              text={t(`sidebar.contact`)}
              type="menu-option"
            />
          </OptionWrapper>
          <OptionWrapper
            onMouseEnter={() => toggleHover(setHoverLanguage, !hoverLanguage)}
            onMouseLeave={() => toggleHover(setHoverLanguage, !hoverLanguage)}
            onClick={changeLanguage}
            className="mr-5 d-none d-lg-flex"
          >
            <OptionIcon src={hoverLanguage ? LanguageActive : LanguageInactive} />
            <OptionLabel
              className="mr-5"
              text={hoverLanguage ? t(`header.changeLang`) : t(`header.currentLang`)}
              type="menu-option"
            />
          </OptionWrapper>
        </OptionsContainer>
        <ResponsiveOptionsContainer className="mr-3 d-lg-none">
          <OptionWrapper
            onMouseEnter={() => toggleHover(setHoverLanguage, !hoverLanguage)}
            onMouseLeave={() => toggleHover(setHoverLanguage, !hoverLanguage)}
            onClick={openLangMenu}
            className="mr-3"
          >
            <OptionIcon src={(hoverLanguage || isMobile) ? LanguageActive : LanguageInactive} />
          </OptionWrapper>
          <MenuToggleContainer onClick={toggleShowMenu}>
            <MenuToggle src={MenuToggleIcon} />
          </MenuToggleContainer>
        </ResponsiveOptionsContainer>
      </HeaderContainer>
      {showMenu && <FloatingMenu innerRef={floatingMenuRef} onClose={onClose} isLang={langOpts} showSubmenu={openSubmenu} onLanguageChange={changeLanguage} />}
      {showServicesSubmenu && <ServicesSubmenu onClose={onCloseSubmenu} innerRef={servicesSubmenuRef} />}
    </>
  );
}

export default Header;
