import React from 'react';
import styled from 'styled-components';
import { Card } from 'react-bootstrap';
import Styles from './CardComponent.Styles';
import CustomText from '../CustomText';
import fileActive from '../../../assets/icons/fileActive.svg';
import LOGO_JAL from '../../../assets/logo_jal.png';

const Container = styled(Card)`${Styles.container}`;
const YellowText = styled.div`${Styles.yellowText}`;
const ContainerText = styled.div`${Styles.containerText}`;
const YellowCircle = styled.div`${Styles.yellowCircle}`;
const CardContainer = styled.div`${Styles.cardContainer}`;
const SectorContainer = styled.div`${Styles.sectorContainer}`;
const Icon = styled.img`${Styles.icon}`;
const Image = styled.img`${Styles.img}`;
const ImageContainer = styled.div`${Styles.imageContainer}`;

function CardComponent({ project }) {
  return (
    <Container>
      <CardContainer>
        <ImageContainer>
          <Image src={LOGO_JAL} />
        </ImageContainer>
        <YellowText>{project.year}</YellowText>
        <CustomText text={project.name} type="subtitle" size={24} lineHeight={0} marginBottom={0} />
        <CustomText text={project.company} type="menu-option" size={18} lineHeight={0} marginBottom={0} />
        <CustomText text={project.description} type="card-small-description" marginTop={20} marginBottom={20} />
        <ContainerText>
          <YellowCircle />
          <CustomText
            text={project.category1}
            type="card-small-description"
            color="#BEBEBE"
          />
        </ContainerText>
        <ContainerText>
          <YellowCircle />
          <CustomText
            text={project.category1}
            type="card-small-description"
            color="#BEBEBE"
          />
        </ContainerText>
        <ContainerText>
          <YellowCircle />
          <CustomText
            text={project.category1}
            type="card-small-description"
            color="#BEBEBE"
          />
        </ContainerText>
      </CardContainer>
      <SectorContainer>
        <Icon src={fileActive} width={30} />
        <CustomText type="caption-regular" text={project.sector} size={14} />
      </SectorContainer>
    </Container>
  );
}

export default CardComponent;
