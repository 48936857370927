export default {
  container: {
    display: `flex`,
    paddingTop: `max(5vw, 40px)`,
    paddingLeft: `max(5.4vw, 25px)`,
    paddingRight: `max(5.4vw, 25px)`,
    paddingBottom: `max(5vw, 40px)`,
    flexDirection: `column`,
    borderRadius: `30px`,
    height: `100%`,
    width: `100%`,
    minHeight: `max(28.5vw, 600px)`,
    margin: `max(1vw, 30px)`
  },
  title: {
    fontFamily: `red-hat-black`,
    fontSize: `max(2vw, 40px)`,
    lineHeight: `max(2.76vw, 53px)`,
    letterSpacing: `0px`,
    color: `#242421`,
    opacity: 1,
    textOverflow: `ellipsis`,
    overflow: `hidden`
  },
  subtitle: {
    fontFamily: `geologica-extra-light`,
    fontSize: `max(1vw, 20px)`,
    lineHeight: `max(1.25vw, 25px)`,
    letterSpacing: `1px`,
    opacity: 1
  },
  description: {
    fontFamily: `geologica-extra-light`,
    fontSize: `max(0.83vw, 16px)`,
    lineHeight: `max(1.66vw, 32px)`,
    letterSpacing: `0px`,
    color: `#242421`,
    opacity: 1,
    marginTop: `max(2.3vw, 15px)`
  }
};
