import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

// assets
import Styles from './Option.styles';

// components
import CustomText from '../../../atoms/CustomText';

const FooterOptionWrapper = styled.a`${Styles.footerOptionWrapper}`;
const FooterOptionIcon = styled.img`${Styles.footerOptionIcon}`;

function Option({
  children,
  link,
  icon,
  preventDefault = false,
  maxWidth
}) {
  const navigate = useNavigate();

  const goToPage = () => {
    navigate(link);
  };

  return (
    <FooterOptionWrapper
      onClick={(e) => {
        if (!preventDefault) {
          goToPage();
        } else {
          e.preventDefault();
          window.open(link, `_blank`);
        }
      }}
      style={{ maxWidth }}
    >
      {icon && <FooterOptionIcon src={icon} />}
      <CustomText text={children} type="footer-option" />
    </FooterOptionWrapper>
  );
}

export default Option;
