import React from 'react';
import styled from 'styled-components';
import Container from 'react-bootstrap/Container';
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

// assets
import Styles from './FloatingMenu.styles';

// components
import CustomText from '../../../atoms/CustomText';
import ServicesIcon from '../../../../assets/icons/servicesActive.svg';
import AboutUsIcon from '../../../../assets/icons/aboutUsActive.svg';
import PoliciesIcon from '../../../../assets/icons/policiesActive.svg';
import ContactIcon from '../../../../assets/icons/contactActive.svg';

const MenuContainer = styled(Container)`${Styles.menuContainer}`;
const MenuOptionWrapper = styled.a`${Styles.menuOptionWrapper}`;
const MenuOptionIcon = styled.img`${Styles.menuOptionIcon}`;

function LangOpts({ onLanguageChange, onClose, innerRef }) {
  const { t } = useTranslation();
  return (
    <MenuContainer ref={innerRef} className="pl-5">
      <MenuOptionWrapper
        onClick={() => {
          onLanguageChange();
          onClose();
        }}
      >
        <CustomText text={t(`header.changeLang`)} type="menu-option" />
      </MenuOptionWrapper>
    </MenuContainer>
  );
}

function MenuOpts({ onClose, innerRef, showSubmenu }) {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const lang = i18n.resolvedLanguage;

  const goToPage = (link) => {
    navigate(`/${lang}/${link}`);
    onClose();
  };

  return (
    <MenuContainer ref={innerRef} className="pl-5">
      <MenuOptionWrapper onClick={() => { showSubmenu(); }}>
        <MenuOptionIcon src={ServicesIcon} />
        <CustomText text={t(`sidebar.services`)} type="menu-option" />
      </MenuOptionWrapper>
      <MenuOptionWrapper onClick={() => { goToPage(t(`routes.aboutUs`)); }}>
        <MenuOptionIcon src={AboutUsIcon} />
        <CustomText text={t(`sidebar.aboutUs`)} type="menu-option" />
      </MenuOptionWrapper>
      <MenuOptionWrapper onClick={() => { goToPage(t(`routes.policies`)); }}>
        <MenuOptionIcon src={PoliciesIcon} />
        <CustomText text={t(`sidebar.policies`)} type="menu-option" />
      </MenuOptionWrapper>
      <MenuOptionWrapper onClick={() => { goToPage(t(`routes.contact`)); }}>
        <MenuOptionIcon src={ContactIcon} />
        <CustomText text={t(`sidebar.contact`)} type="menu-option" />
      </MenuOptionWrapper>
      <MenuOptionWrapper onClick={() => { goToPage(t(`routes.projects`)); }}>
        <MenuOptionIcon src={ContactIcon} />
        <CustomText text={t(`sidebar.projects`)} type="menu-option" />
      </MenuOptionWrapper>
    </MenuContainer>
  );
}

function FloatingMenu({
  isLang, onClose, onLanguageChange, innerRef, showSubmenu
}) {
  return isLang
    ? <LangOpts innerRef={innerRef} onClose={onClose} onLanguageChange={onLanguageChange} />
    : <MenuOpts innerRef={innerRef} onClose={onClose} showSubmenu={showSubmenu} />;
}

export default FloatingMenu;
