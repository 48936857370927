import React, { createContext, useReducer, useEffect } from 'react';

const store = createContext(undefined);
const { Provider } = store;

const actionTypes = {
  updateWindowWidth: `UPDATE_WINDOW_WIDTH`,
  updateIsMobile: `UPDATE_IS_MOBILE`
};

const reducer = (state, action) => {
  const localState = { ...state };
  switch (action.type) {
  case actionTypes.updateWindowWidth:
    localState.windowWidth = action.payload;
    return localState;
  case actionTypes.updateIsMobile:
    localState.isMobile = action.payload;
    return localState;
  default:
    return localState;
  }
};

function ContextProvider({ children }) {
  const initialState = {
    windowWidth: window.innerWidth,
    isMobile: window.innerWidth < 892
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  const updateDisplayValues = () => {
    const width = window.innerWidth;

    const isMobile = width < 892;

    dispatch({
      type: actionTypes.updateWindowWidth,
      payload: width
    });
    dispatch({
      type: actionTypes.updateIsMobile,
      payload: isMobile
    });
  };

  useEffect(() => {
    window.addEventListener(`resize`, updateDisplayValues);
    return () => window.removeEventListener(`resize`, updateDisplayValues);
  }, []);

  return <Provider value={state}>{children}</Provider>;
}

export { store, ContextProvider };
