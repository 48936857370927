export default {
  container: {
    display: `flex`,
    marginLeft: 0,
    marginRight: 0,
    justifyContent: `center`,
    marginTop: `55px`
  },
  section: {
    paddingLeft: `max(4.8vw, 30px)`,
    paddingRight: `max(4.8vw, 30px)`,
    marginBottom: `50px`
  },
  title: {
    fontFamily: `red-hat-black`,
    fontWeight: 900,
    fontSize: `max(2vw, 40px)`,
    lineHeight: `max(2.76vw, 53px)`,
    color: `#242421`,
    display: `block`
  },
  subtitle: {
    fontFamily: `geologica-extra-light`,
    fontSize: `max(1vw, 20px)`,
    lineHeight: `max(1.25vw, 25px)`,
    letterSpacing: `1px`,
    color: `#ADADAD`,
    display: `block`
  },
  description: {
    fontFamily: `geologica-extra-light`,
    fontSize: `max(0.83vw, 16px)`,
    lineHeight: `max(1.66vw, 32px)`,
    color: `#242421`,
    display: `block`
  },
  bold: {
    fontFamily: `geologica-bold`,
    fontSize: `max(0.83vw, 16px)`,
    lineHeight: `max(1.66vw, 32px)`,
    color: `#242421`
  }
};
