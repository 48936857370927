// Libs imports
import React from 'react';
import styled from 'styled-components';

// Styles
import Styles from './Policy.styles';

// components
import Hero from './components/Hero';
import Intro from './components/Intro';
import ConductCode from './components/ConductCode';
import AntiCorruption from './components/Anticorruption';
import ContactUs from './components/ContactUs';

const Container = styled.div`${Styles.container}`;

function Policy() {
  return (
    <Container>
      <Hero />
      <Intro />
      <ConductCode />
      <AntiCorruption />
      <ContactUs />
    </Container>
  );
}

export default Policy;
