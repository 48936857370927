export default {
  container: {
    backgroundColor: `#FFFFFF`,
    borderRadius: `30px`,
    boxShadow: `0px 20px 99px #00000015`,
    paddingLeft: `max(7.55vw, 56.5px)`,
    paddingRight: `max(7.55vw, 57.5px)`,
    marginBottom: `max(1vw, 20px)`,
    '@media (max-width: 992px)': {
      paddingLeft: `max(7.55vw, 35px)`,
      paddingRight: `max(7.55vw, 35px)`
    }
  },
  title: {
    fontFamily: `red-hat-black`,
    fontWeight: 900,
    fontSize: `max(1.77vw, 34px)`,
    lineHeight: `max(2.3vw, 45px)`,
    color: `#242421`,
    display: `block`
  },
  subtitle: {
    fontFamily: `geologica-extra-light`,
    fontSize: `max(1vw, 20px)`,
    lineHeight: `max(1.25vw, 25px)`,
    letterSpacing: `1px`,
    color: `#242421`,
    display: `block`
  },
  description: {
    fontFamily: `geologica-extra-light`,
    fontSize: `max(0.83vw, 16px)`,
    lineHeight: `max(1.66vw, 32px)`,
    color: `#242421`
  }
};
