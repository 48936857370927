import React from 'react';
import styled from 'styled-components';
import Col from 'react-bootstrap/Col';

// styles
import Styles from './CardWithColor.styles';

// components
const CardContainer = styled(Col)`${Styles.container}`;
const Title = styled.small`${Styles.title}`;
const Subtitle = styled.small`${Styles.subtitle}`;
const Description = styled.small`${Styles.description}`;

function CardWithColor({
  color, subtitleColor, title, subtitle, description
}) {
  return (
    <CardContainer style={{ backgroundColor: color }} className="col-12 col-lg-5">
      <Title>{title}</Title>
      <Subtitle style={{ color: subtitleColor }}>{subtitle}</Subtitle>
      <Description dangerouslySetInnerHTML={{ __html: description }} />
    </CardContainer>
  );
}

export default CardWithColor;
