import React, { useContext } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

// context
import { store } from '../../../../store';

// styles
import Styles from './Values.styles';

// components
import CustomText from '../../../../components/atoms/CustomText';
import Value from './components/Value';

const Container = styled.div`${Styles.container}`;
const TitleContainer = styled.div`${Styles.titleContainer}`;
const ValuesContainer = styled.div`${Styles.valuesContainer}`;

function Values() {
  const { isMobile } = useContext(store);
  const { t } = useTranslation();

  return (
    <>
      <Container
        style={{
          paddingLeft: isMobile ? `30px` : `19vw`,
          paddingRight: isMobile ? `30px` : `15vw`
        }}
      >
        <TitleContainer>
          <CustomText text={t(`pages.aboutUs.values.title`)} type="subtitle" />
        </TitleContainer>
        <CustomText text={t(`pages.aboutUs.values.description`)} type="caption" className="mb-3" />
      </Container>
      <ValuesContainer
        style={{
          paddingLeft: isMobile ? `30px` : `10vw`,
          paddingRight: isMobile ? `30px` : `10vw`
        }}
      >
        <Value
          title={t(`pages.aboutUs.values.empathy.title`)}
          subtitle={t(`pages.aboutUs.values.empathy.subtitle`)}
          description={t(`pages.aboutUs.values.empathy.description`)}
        />
        <Value
          title={t(`pages.aboutUs.values.resolutions.title`)}
          subtitle={t(`pages.aboutUs.values.resolutions.subtitle`)}
          description={t(`pages.aboutUs.values.resolutions.description`)}
        />
        <Value
          title={t(`pages.aboutUs.values.adapt.title`)}
          subtitle={t(`pages.aboutUs.values.adapt.subtitle`)}
          description={t(`pages.aboutUs.values.adapt.description`)}
        />
        <Value
          title={t(`pages.aboutUs.values.respect.title`)}
          subtitle={t(`pages.aboutUs.values.respect.subtitle`)}
          description={t(`pages.aboutUs.values.respect.description`)}
        />
        <Value
          title={t(`pages.aboutUs.values.responsable.title`)}
          subtitle={t(`pages.aboutUs.values.responsable.subtitle`)}
          description={t(`pages.aboutUs.values.responsable.description`)}
        />
        <Value
          title={t(`pages.aboutUs.values.creators.title`)}
          subtitle={t(`pages.aboutUs.values.creators.subtitle`)}
          description={t(`pages.aboutUs.values.creators.description`)}
        />
        <Value
          title={t(`pages.aboutUs.values.integrity.title`)}
          subtitle={t(`pages.aboutUs.values.integrity.subtitle`)}
          description={t(`pages.aboutUs.values.integrity.description`)}
        />
      </ValuesContainer>
    </>
  );
}

export default Values;
