export default {
  footerOptionWrapper: {
    display: `flex`,
    flexDirection: `row`,
    cursor: `pointer`,
    marginBottom: `10px`
  },
  footerOptionIcon: {
    height: `1.5rem`,
    marginRight: `5px`
  }
};
