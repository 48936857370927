import React, { useContext } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import Row from 'react-bootstrap/Row';

// context
import { store } from '../../../../store';

// styles
import Styles from './Achievements.styles';

// assets
import projects from '../../../../assets/achievements/projects.svg';
import collaborators from '../../../../assets/achievements/collaborators.svg';
import certifications from '../../../../assets/achievements/certifications.svg';
import experience from '../../../../assets/achievements/experience.svg';
import YellowCircle from '../../../../assets/yellowCircle.png';

// components
import CustomText from '../../../../components/atoms/CustomText';
import SimpleCardWithImage from '../../../../components/atoms/SimpleCardWithImage';

const MainContainer = styled.div`${Styles.container}`;
const CardsContainer = styled(Row)`${Styles.cardsContainer}`;
const TextContainer = styled.div`${Styles.textContainer}`;
const Title = styled.small`${Styles.title}`;
const TitleBold = styled.small`${Styles.titleBold}`;
const ThankYou = styled.small`${Styles.thankYou}`;

const RightEllipsis = styled.img`${Styles.rightEllipsis}`;
const LeftEllipsis = styled.img`${Styles.leftEllipsis}`;

function Achievements() {
  const { t } = useTranslation();
  const { isMobile } = useContext(store);

  return (
    <MainContainer
      style={{
        paddingBottom: isMobile ? `155px` : `3.6vw`
      }}
    >
      <Title>
        {t(`pages.aboutUs.achievements.title`)}
        <TitleBold>
          {t(`pages.aboutUs.achievements.titleBold`)}
        </TitleBold>
      </Title>
      <CardsContainer>
        <SimpleCardWithImage
          image={projects}
          title={t(`pages.aboutUs.achievementsSection.cards.projects.title`)}
          subtitle={t(`pages.aboutUs.achievementsSection.cards.projects.subtitle`)}
        />
        <SimpleCardWithImage
          image={certifications}
          title={t(`pages.aboutUs.achievementsSection.cards.collaborators.title`)}
          subtitle={t(`pages.aboutUs.achievementsSection.cards.collaborators.subtitle`)}
        />
        <SimpleCardWithImage
          image={collaborators}
          title={t(`pages.aboutUs.achievementsSection.cards.certifications.title`)}
          subtitle={t(`pages.aboutUs.achievementsSection.cards.certifications.subtitle`)}
        />
        <SimpleCardWithImage
          image={experience}
          title={t(`pages.aboutUs.achievementsSection.cards.experience.title`)}
          subtitle={t(`pages.aboutUs.achievementsSection.cards.experience.subtitle`)}
        />
      </CardsContainer>
      <TextContainer
        style={{
          paddingLeft: isMobile ? 0 : `6.3vw`,
          paddingRight: isMobile ? 0 : `6vw`
        }}
      >
        <CustomText
          type="caption"
          text={t(`pages.aboutUs.achievements.paragraph1`)}
          className="mb-3"
        />
        <CustomText
          type="caption"
          text={t(`pages.aboutUs.achievements.paragraph2`)}
          className="mb-3"
        />
        <CustomText
          type="caption"
          text={t(`pages.aboutUs.achievements.paragraph3`)}
        />
        <ThankYou>
          {t(`pages.aboutUs.achievements.boldText`)}
        </ThankYou>
      </TextContainer>
      <RightEllipsis
        style={{
          bottom: `max(8.75vw, 100px)`,
          right: isMobile ? `-15%` : `3.95vw`
        }}
        src={YellowCircle}
      />
      <LeftEllipsis
        style={{
          bottom: isMobile ? `-5%` : `-15%`,
          left: isMobile ? `-15%` : `3.95vw`
        }}
        src={YellowCircle}
      />
    </MainContainer>
  );
}

export default Achievements;
