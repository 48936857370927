import React, { useContext } from 'react';
import styled from 'styled-components';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

// context
import { store } from '../../../../../../store';

// styles
import Styles from './Value.styles';

// components

const Container = styled(Row)`${Styles.container}`;
const Title = styled.small`${Styles.title}`;
const Subtitle = styled.small`${Styles.subtitle}`;
const Description = styled.small`${Styles.description}`;

function Value({ subtitle, title, description }) {
  const { isMobile } = useContext(store);

  return (
    <Container
      className="ml-0 mr-0"
      style={{
        paddingTop: isMobile ? `55px` : `32px`,
        paddingBottom: isMobile ? `50px` : `32px`
      }}
    >
      <Col xs={12} md={12} lg={4}>
        <Subtitle>{subtitle}</Subtitle>
        <Title>{title}</Title>
      </Col>
      <Col xs={12} md={12} lg={8}>
        <Description dangerouslySetInnerHTML={{ __html: description }} />
      </Col>
    </Container>
  );
}

export default Value;
