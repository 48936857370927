export default {
  container: {
    borderRadius: `30px`
  },
  inner: {
    display: `flex`,
    paddingTop: `max(4vw, 40px)`,
    paddingLeft: `max(4vw, 20px)`,
    paddingRight: `max(4vw, 20px)`,
    paddingBottom: `max(4vw, 40px)`,
    flexDirection: `column`,
    cursor: `pointer`,
    borderRadius: `30px`,
    minHeight: `28.5vw`,
    backgroundColor: `#FFFFFF`
  },
  img: {
    display: `flex`,
    maxWidth: `max(50%, 212px)`,
    height: `max(9vw, 180px)`,
    objectFit: `contain`,
    alignSelf: `flex-start`
  }
};
