export default {
  container: {
    alignItems: `center`,
    minHeight: `22.2vw`,
    paddingLeft: `min(20vw, 30px)`,
    paddingRight: `min(16vw, 30px)`,
    backgroundColor: `#F7F7F7`
  },
  welcomeImage: {
    height: `max(15vw, 290px)`,
    alignSelf: `center`
  }
};
